export class Config {

    firebase_test = {

        apiKey: 'AIzaSyALcDXdd4tT5IAX4wA1ZVoeI3mJELf-Ypg',
        authDomain: 'aigensstoretest.firebaseapp.com',
        databaseURL: 'https://aigensstoretest.firebaseio.com',
        projectId: 'aigensstoretest',
        storageBucket: '<your-storage-bucket>',
        messagingSenderId: '<your-messaging-sender-id>',
        auth: {uid: 'aigens'}

    };

    firebase_prd = {

        apiKey: 'AIzaSyAFTRErjVB_m9MEgv9-5v4M0WPiRmVjXpQ',
        authDomain: 'aigensstoreapp.firebaseapp.com',
        databaseURL: 'https://aigensstoreapp.firebaseio.com',
        projectId: 'aigensstoreapp',
        storageBucket: '<your-storage-bucket>',
        messagingSenderId: '<your-messaging-sender-id>'

    };

    dev = {
        api: 'https://test-dot-aigensstoretest.appspot.com',
        statusHost: 'https://us-central1-order-place-dev.cloudfunctions.net/storeStatus',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1525853386107',
        country: 'hk',
        locale: 'zh',
        production: false,
        license: '粤ICP备17066462号'
    };


    test = {
        api: 'https://aigensstoretest.appspot.com',
        statusHost: 'https://us-central1-order-place-dev.cloudfunctions.net/storeStatus',
        applePay: 'https://us-central1-aigensstoretest.cloudfunctions.net/applePaySession',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1000',
        country: 'hk',
        locale: 'zh',
        production: false,
    };

    testPoint = {
        api: 'https://point-as-payment-dot-aigensstoretest.appspot.com',
        statusHost: 'https://us-central1-order-place-dev.cloudfunctions.net/storeStatus',
        applePay: 'https://us-central1-aigensstoretest.cloudfunctions.net/applePaySession',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1000',
        country: 'hk',
        locale: 'zh',
        production: false,
    };

    testPrice = {
        api: 'https://nandos-price-zero-dot-aigensstoretest.appspot.com/',
        statusHost: 'https://us-central1-order-place-dev.cloudfunctions.net/storeStatus',
        applePay: 'https://us-central1-aigensstoretest.cloudfunctions.net/applePaySession',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1000',
        country: 'hk',
        locale: 'zh',
        production: false,
    };

    aauat = {
        api: 'https://aa-fos-uat.appspot.com',
        statusHost: 'https://us-central1-order-place-dev.cloudfunctions.net/storeStatus',
        applePay: 'https://us-central1-aigensstoretest.cloudfunctions.net/applePaySession',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1000',
        country: 'hk',
        locale: 'zh',
        production: false,
    };

    testAA = {
        api: 'https://aa-fos-uat.appspot.com',
        statusHost: 'https://us-central1-order-place-dev.cloudfunctions.net/storeStatus',
        applePay: 'https://us-central1-aigensstoretest.cloudfunctions.net/applePaySession',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1000',
        country: 'hk',
        locale: 'zh',
        production: false,
    };

    fairwooduat = {
        api: 'https://fairwood-test.appspot.com',
        statusHost: 'https://us-central1-order-place-dev.cloudfunctions.net/storeStatus',
        applePay: 'https://us-central1-aigensstoretest.cloudfunctions.net/applePaySession',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '60000',
        country: 'hk',
        locale: 'zh',
        production: false,
        imageHost: 'https://d6tkbe3qejtku.cloudfront.net'
    };

    fairwoodprd = {
        api: 'https://fairwoodapp.appspot.com',
        statusHost: 'https://us-central1-aigens-byod.cloudfunctions.net/storeStatus',
        applePay: 'https://us-central1-aigensstoreapp.cloudfunctions.net/applePaySession',
        stripe: 'pk_live_GbZT9bpof35zDVDymMwK0qEC',
        groupId: '60000',
        country: 'hk',
        locale: 'zh',
        production: true,
        orderplacetnc: true
    };

    testcng = {
        api: 'https://apitestcn.order.place',
        imageHost: 'https://image.aigens.cn',
        applePay: 'https://us-central1-aigensstoretest.cloudfunctions.net/applePaySession',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1000',
        country: 'cn',
        locale: 'zh-cn',
        production: false,
        license: '粤ICP备17066462号'
    };


    prd = {
        api: 'https://aigensstoreapp.appspot.com',
        statusHost: 'https://us-central1-aigens-byod.cloudfunctions.net/storeStatus',
        applePay: 'https://us-central1-aigensstoreapp.cloudfunctions.net/applePaySession',
        stripe: 'pk_live_GbZT9bpof35zDVDymMwK0qEC',
        groupId: '1000',
        country: 'hk',
        locale: 'zh',
        production: true
    };

    prdcng = {
        api: 'https://apicn.order.place',
        imageHost: 'https://image.aigens.cn',
        applePay: 'https://us-central1-aigensstoreapp.cloudfunctions.net/applePaySession',
        stripe: 'pk_live_GbZT9bpof35zDVDymMwK0qEC',
        groupId: '1000',
        country: 'cn',
        locale: 'zh',
        production: true,
        license: '粤ICP备17066462号'
    };

    prdbk = {
        api: 'https://bksgapp.appspot.com',
        stripe: 'pk_live_oyrSyEv5dMtZEFGp9j9ThNHL',
        groupId: '80000',
        country: 'hk',
        locale: 'zh',
        production: true
    };

    prdPizzaHut = {
        api: 'https://yumsgapp.appspot.com',
        stripe: 'pk_live_oyrSyEv5dMtZEFGp9j9ThNHL',
        groupId: '47110001',
        country: 'hk',
        locale: 'zh',
        production: true
    };

    prdback = {
        api: 'https://backend-dot-aigensstoreapp.appspot.com',
        stripe: 'pk_live_GbZT9bpof35zDVDymMwK0qEC',
        groupId: '1000',
        country: 'hk',
        locale: 'zh',
        production: true
    };

    cntest = {

        api: 'https://storetest.aigens.cn',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1000',
        country: 'cn',
        locale: 'zh-cn',
        production: false,
        license: '粤ICP备17066462号'
    };

    cnprd = {

        api: 'https://store.aigens.cn',
        stripe: 'pk_test_Qz98hi2rnymhXsjWGfplG5dc',
        groupId: '1000',
        country: 'cn',
        locale: 'zh-cn',
        production: true,
        license: '粤ICP备17066462号'
    };

    cdc_test = {
        name: 'cdc_test',
        api: 'https://cdctesthk.appspot.com',
        statusApi: 'https://dev.order.place/api/v1/store/status.json',
        byodHost: 'https://byodtest.aigens.com',
        groupId: '50000',
        country: 'hk',
        locale: 'zh',
        display: 'CDC(測試)',
        ably: 'iVS_iA.7LJpUg:HxTrWmMFQu_XqaBJ',
        production: false
    };

    domains = {
        'byodtest.aigens.com': this.test,
        'byod.aigens.com': this.prd,
        'order.place': this.prd,
        'appstest.aigens.com.cn': this.cntest,
        'apps.aigens.com.cn': this.cnprd,
        'genkiordertest.aigens.cn': this.cntest,
        'genki.aigens.cn': this.cnprd,
        'ordertest.aigens.cn': this.cntest,
        'demo-v3.aigens.cn': this.cntest,
        'order.aigens.cn': this.cnprd,
        'v3.aigens.cn': this.cnprd,
        'fairwood.aigens.cn': this.cnprd,
        'test.order.place': this.test,
        'now.order.place': this.prd,
        'now2.order.place': this.prd,
        'nowcn.order.place': this.prdcng,
        'mx.order.place': this.prd,
        'ssp.order.place': this.prd,
        'dev.order.place': this.test,
        'devcn.order.place': this.testcng,
        'opdevcng.aigens.cn': this.testcng,
        'devop.aigens.cn': this.testcng,
        'uat.order.place': this.test,
        'treats.order.place': this.prd,
        'bksg.order.place': this.prdbk,
        'genki.order.place': this.prd,
        'canteen.order.place': this.prd,
        'ippudo.order.place': this.prd,
        'genki2.order.place': this.prd,
        'ippudo2.order.place': this.prd,
        'hkday.order.place': this.prd,
        'apc.order.place': this.prd,
        'heeretea.order.place': this.prd,
        'pizzahutsg.order.place': this.prdPizzaHut,
        'order-place-test.firebaseapp.com': this.test,
        'fairwood-byod-uat.firebaseapp.com': this.fairwooduat,
        'localhost': this.test,
        'demo-v2.order.place': this.test,
        'v2.order.place': this.prd,
        'sales.order.place': this.test,
        'prerelease.order.place': this.prd,
        'aa-fos-uat-mobile.firebaseapp.com': this.testAA,
        'citysuper-uat-order-place.firebaseapp.com': this.test,
        'citysuper-amazingfoodhall-ts.order.place': this.prd,
        'citysuper-uat.order.place': this.test,
        'internal-byod-test.firebaseapp.com': this.test,
        'point-feat.firebaseapp.com': this.testPoint,
        'internal-v2.order.place': this.test,
        'internal-prd.order.place': this.prd,
        'internal-byod-test2.firebaseapp.com': this.test,
        'test-prd.firebaseapp.com': this.prd,
        'price-test.firebaseapp.com': this.testPrice,
        'res.order.place': this.prd,
        'fairwood-uat.order.place': this.fairwooduat,
        'fairwood.order.place': this.fairwoodprd,
        'cdc-order-place-dev2.firebaseapp.com': this.cdc_test,
        'ssp-uat.web.app': this.test,
        'ssp-eu.web.app': this.prd,
        'starbucksfr.order.place': this.prd,
        'brioche.order.place': this.prd,
        'bktw.order.place': this.prd,
        'panos.order.place': this.prd
    };

    ga_prd = {
        name: 'prd',
        trackerId: 'UA-145294217-2',

    };

    ga_uat = {
        name: 'uat',
        trackerId: 'UA-145294217-1',

    };


    trackers = {
        'ssp.order.place': {
            name: 'ssp',
            trackerId: 'UA-110066691-4',

        }
    };


    languages = ['en', 'zh', 'zh-cn', 'ja', 'ko', 'de'];

    langNames = {
        'zh': '繁體中文',
        'zh-cn': '简体中文',
        'en': 'English',
        'ja': '日本語',
        'ko': '한국어',
        'fr': 'Français',
        'nl': 'Nederlands',
        'de': 'Deutsch',
        'th': 'Thai'
    };

    regions = [];

    appId = 'aigens-order-place';

    default = this.pickConfig();

    pickConfig() {

        const domain = window.location.hostname;

        console.log('domain', domain);
        let region = this.domains[domain];

        if (!region) {

            if (domain.indexOf('aigens.cn') > 0) {
                region = this.prdcng;
            } else {

                region = this.test;
            }
        }

        console.log('region', region);

        if (!region) {
            region = this.cnprd;
        }
        return region;
    }

    gaConfig() {
        const domain = window.location.hostname;
        console.log('domain', domain);
        let tracker = this.trackers[domain];
        console.log('trackId', tracker);

        if (!tracker) {
            tracker = null;
        }
        return tracker;
    }

    getFirebaseConfig(): any {

        // console.log("fbconfig??", Config.firebase_test);
        const domain = window.location.hostname;

        if (domain === 'fairwood.order.place') {
            return {
                apiKey: 'AIzaSyA75lJo3fK6PQu-GiYlHTKJ-cDCUoIF84U',
                authDomain: 'fairwoodapp.firebaseapp.com',
                databaseURL: 'https://fairwoodapp.firebaseio.com',
                projectId: 'fairwoodapp',
                storageBucket: 'fairwoodapp.appspot.com',
                messagingSenderId: '590569411961',
                appId: '1:590569411961:web:61567abc54a4c0e6fd8ba8'
            };
        } else if (this.default.production) {
            console.log('firebase prd');
            return this.firebase_prd;
        } else {
            console.log('firebase test');
            return this.firebase_test;
        }


    }

}

export class FireStoreConfig {
    static realtimeTest = {
        apiKey: 'AIzaSyD7y6JTzHLdsP6-tGurav8cAWc5HVY2jFY',
        authDomain: 'aigens-realtime-test.firebaseapp.com',
        databaseURL: 'https://aigens-realtime-test.firebaseio.com',
        projectId: 'aigens-realtime-test',
    };

    static getConfig() {
        return this.realtimeTest;
    }

}
