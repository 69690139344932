var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import 'rxjs/Rx';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
var SystemConfigService = /** @class */ (function (_super) {
    __extends(SystemConfigService, _super);
    function SystemConfigService(http, configs) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        /**
         * {
         * "id": "S5685495992418304-byod",
            "data": {
                "P2": "V2",
                "P3": "V3",
                "translations": {
                    "super": "man",
                    "hello": "world"
                },
                "templateId": "e6ac2da6-6c1a-4497-b037-b08b0fbd39a6",
                "settings": {
                    "batchOrder": true
                }
            },
            "type": null
         * }
         */
        _this.systemConfig = {};
        _this.systemBrandConfig = {};
        _this.systemCourtConfig = {};
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    Object.defineProperty(SystemConfigService.prototype, "systemConfigIsEmpty", {
        get: function () {
            return (Object.keys(this.systemConfig).length === 0) || (!this.systemConfig);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "systemBrandConfigIsEmpty", {
        get: function () {
            return (Object.keys(this.systemBrandConfig).length === 0) || (!this.systemBrandConfig);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "systemCourtConfigIsEmpty", {
        get: function () {
            return (Object.keys(this.systemCourtConfig).length === 0) || (!this.systemCourtConfig);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hidePromoCodeInputInCourtLevel", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hidePromoCodeInputInCourtLevel']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "courtShowOrderQueueInCart", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtShowOrderQueueInCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "isBatchOrder", {
        get: function () {
            var batchOrder = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['settings'] && this.systemConfig['data']['settings']['batchOrder']) || false;
            return batchOrder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "menuLayout", {
        get: function () {
            var layout = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['layout']) || {};
            return layout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "upsellingMoveTop", {
        get: function () {
            var layout = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['upsellingMoveTop']) || false;
            return layout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "nameLimit", {
        get: function () {
            // for ssp  name filed input should limit maximum
            var layout = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['nameLimit']) || false;
            return layout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "enableCoupon", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableCoupon']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showOrderQueue", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueue']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showOrderQueueInCart", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueueInCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideLoginPageRegister", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPageRegister']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideMemberProfilePage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideMemberProfilePage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "preorderNoNeedRedirectCart", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['preorderNoNeedRedirectCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "preorderShowLogin", {
        get: function () {
            // prderOrder回来后 停留在homepage 并且弹出loginmodal
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['preorderShowLogin']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideLoginPagePhoneInput", {
        get: function () {
            var brandRes = (this.systemBrandConfig && this.systemBrandConfig['data'] && this.systemBrandConfig['data']['hideLoginPagePhoneInput']) || false;
            var storeRes = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPagePhoneInput']) || false;
            return brandRes || storeRes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideLoginPageEmailInput", {
        get: function () {
            var brandRes = (this.systemBrandConfig && this.systemBrandConfig['data'] && this.systemBrandConfig['data']['hideLoginPageEmailInput']) || false;
            var storeRes = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPageEmailInput']) || false;
            return brandRes || storeRes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideCourtLoginPageEmailInput", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtLoginPageEmailInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "pointsRewardsPromocodeStandalone", {
        get: function () {
            // points\Rewards\Promocode 只能选一个
            var resCourt = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['pointsRewardsPromocodeStandalone']) || false;
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pointsRewardsPromocodeStandalone']) || false;
            return res || resCourt;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideCourtRegister", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtRegister']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "checkCourtMemberInStart", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['checkCourtMemberInStart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideStampPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideStampPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "suspended", {
        get: function () {
            // for ssp project. when pickup mode. should see modes to hide mode-select btn
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['suspended']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "brandStoreListUseListUi", {
        get: function () {
            var brandRes = (this.systemBrandConfig && this.systemBrandConfig['data'] && this.systemBrandConfig['data']['brandStoreListUseListUi']) || false;
            var storeRes = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['brandStoreListUseListUi']) || false;
            return brandRes || storeRes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideResetPassword", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideResetPassword']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showCurrency", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showCurrency']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "estTimeMap", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['estTimeMap']) || [];
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showOrderQueueInOrderStatus", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueueInOrderStatus']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "useConfigPaymentRemind", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['useConfigPaymentRemind']) || {};
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "paymentRemind", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['paymentRemind']) || [];
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showOrderStatusPageCallNo", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderStatusPageCallNo']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hasScrollbar", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hasScrollbar']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showByodSummaryPageBillNo", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showByodSummaryPageBillNo']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hidePriceItemGridCart", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hidePriceItemGridCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "preorderViewOnly", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['preorderViewOnly']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideInputTableButton", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideInputTableButton']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "advanceDeliveryDays", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['advanceDeliveryDays']) || 0;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "advanceTakeawayDays", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['advanceTakeawayDays']) || 0;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "advanceDineinDays", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['advanceDineinDays']) || 0;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "enableDineinPickupTime", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableDineinPickupTime']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "advanceHotelDays", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['advanceHotelDays']) || 0;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "deliveryCutOffTime", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['deliveryCutOffTime']) || 0;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "spotType", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['spotType']) || 'table';
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "pickupCutOffTime", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickupCutOffTime']) || 0;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideTodayOrder", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideTodayOrder']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showPickupTimeWhenCheckout", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showPickupTimeWhenCheckout']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "itemPricePrecision", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['itemPricePrecision'] && this.systemConfig['data']['itemPricePrecision'] > -1);
            if (res) {
                return this.systemConfig['data']['itemPricePrecision'];
            }
            else {
                return -1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "pickUpTimeInterval", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeInterval']) || false;
            if (res) {
                return this.systemConfig['data']['pickUpTimeInterval'];
            }
            else {
                // default
                return 15;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "pickUpTimeCounter", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeCounter']) || false;
            if (res) {
                return this.systemConfig['data']['pickUpTimeCounter'];
            }
            else {
                // default
                return 99;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "pickUpTimeRequired", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeRequired']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hidePickUpTimeDefault", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hidePickUpTimeDefault']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "OnlyModeInBrandStoreListPage", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['OnlyModeInBrandStoreListPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showLoginInCartPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showLoginInCartPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showLoginInMultiCartPage", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['showLoginInMultiCartPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showCourtTNCInCart", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['showCourtTNCInCart']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideWillEarn", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideWillEarn']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "enlargeStoreLocationInfoInSplashPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enlargeStoreLocationInfoInSplashPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showStorePhoneInfoInSplashPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showStorePhoneInfoInSplashPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideOrderNoOnPaid", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideOrderNoOnPaid']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showStoreLocationInfoInByodSummaryPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showStoreLocationInfoInByodSummaryPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "courtGuestInfoNameInput", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtGuestInfoNameInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "courtGuestInfoSpotInput", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtGuestInfoSpotInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideCourtGuestInfoPhoneInput", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtGuestInfoPhoneInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideCourtGuestInfoEmailInput", {
        get: function () {
            var res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtGuestInfoPhoneInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "comboPageGroupExpand", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['comboPageGroupExpand']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "directoryHideLogo", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['directoryHideLogo']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "deliveryCanLogin", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['deliveryCanLogin']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideReorderButton", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideReorderButton']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showNewStatusUi", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showNewStatusUi']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideByodSummaryCallButton", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideByodSummaryCallButton']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideByodSplashLogoContainer", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideByodSplashLogoContainer']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "forceOldLayout", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['forceOldLayout']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "checkItemMax", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['checkItemMax']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "forceRedirectOrderStatus", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['forceRedirectOrderStatus']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideRemark", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideRemark']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "tempDisableBYODTnC", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['tempDisableBYODTnC']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "newlistLayoutInCatList", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['newlistLayoutInCatList']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showLogo", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showLogo']) || true;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideContactless", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideContactless']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideCutlery", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideCutlery']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "usePostal", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['usePostal']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "guestRegistration", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['guestRegistration']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "enableFAQ", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableFAQ']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showLoginInItemGridPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showLoginInItemGridPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "showMemberLoginModalInItemGridPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showMemberLoginModalInItemGridPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "acceptMarketingInPaymentPage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['acceptMarketingInPaymentPage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "guestInfoNameInput", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['guestInfoNameInput']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "disableOrderStatusPageFirebaseListening", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['disableOrderStatusPageFirebaseListening']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "confirmMessage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['confirmMessage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "placedMessage", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['placedMessage']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "trackHint", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['trackHint']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "memberPageHideOrderButton", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['memberPageHideOrderButton']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideDeliveryStatusBar", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideDeliveryStatusBar']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "hideDeliveryAddressButton", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideDeliveryAddressButton']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "backToDirectory", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['backToDirectory']) || '';
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "disableChangeScheduledDate", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['disableChangeScheduledDate']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "defaultContactless", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['defaultContactless']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemConfigService.prototype, "defaultCutlery", {
        get: function () {
            var res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['defaultCutlery']) || false;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * get System Config
     * @param storeId :string|number
     * @param brandId :string|number
     * @param courtId :string|number
     * @param callback (config:any)=> ()
     */
    SystemConfigService.prototype.getSystemConfig = function (storeId, callback) {
        var _this = this;
        if (!storeId)
            return;
        if (this.systemConfigIsEmpty || this.configStoreId !== storeId) {
            this.privateSystemConfig(storeId).subscribe(function (config) {
                _this.configStoreId = storeId;
                console.log('getSystemConfig:', config);
                if (callback)
                    callback(config);
            }, function (err) {
                if (callback)
                    callback({});
            });
        }
        else if (callback) {
            callback(this.systemConfig);
        }
    };
    SystemConfigService.prototype.getSystemBrandConfig = function (brandId, callback) {
        var _this = this;
        if (!brandId)
            return;
        if (this.systemBrandConfigIsEmpty || this.configBrandId !== brandId) {
            this.privateSystemBrandConfig(brandId).subscribe(function (config) {
                _this.configBrandId = brandId;
                console.log('getSystemBrandConfig:', config);
                if (callback)
                    callback(config);
            }, function (err) {
                if (callback)
                    callback({});
            });
        }
        else if (callback) {
            callback(this.systemBrandConfig);
        }
    };
    SystemConfigService.prototype.getSystemCourtConfig = function (courtId, callback) {
        var _this = this;
        if (!courtId)
            return;
        if (this.systemCourtConfigIsEmpty || this.configCourtId !== courtId) {
            this.privateSystemCourtConfig(courtId).subscribe(function (config) {
                _this.configCourtId = courtId;
                console.log('getSystemCourtConfig:', config);
                if (callback)
                    callback(config);
            }, function (err) {
                if (callback)
                    callback({});
            });
        }
        else if (callback) {
            callback(this.systemCourtConfig);
        }
    };
    SystemConfigService.prototype.privateSystemConfig = function (storeId, type) {
        var _this = this;
        if (type === void 0) { type = 'byod'; }
        var url = '/api/v1/store/config.json?type=' + type + '&storeId=' + storeId;
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().map(function (jo) {
            var data = (jo && jo['data']) || {};
            _this.systemConfig = data;
            return data;
        });
    };
    SystemConfigService.prototype.privateSystemBrandConfig = function (brandId, type) {
        var _this = this;
        if (type === void 0) { type = 'byod'; }
        var url = '/api/v1/store/config.json?type=' + type + '&brandId=' + brandId;
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().map(function (jo) {
            var data = (jo && jo['data']) || {};
            _this.systemBrandConfig = data;
            return data;
        });
    };
    SystemConfigService.prototype.privateSystemCourtConfig = function (courtId, type) {
        var _this = this;
        if (type === void 0) { type = 'byod'; }
        var url = '/api/v1/store/config.json?type=' + type + '&courtId=' + courtId;
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().map(function (jo) {
            var data = (jo && jo['data']) || {};
            _this.systemCourtConfig = data;
            return data;
        });
    };
    return SystemConfigService;
}(BaseService));
export { SystemConfigService };
