import 'rxjs/Rx';

import {AQuery} from '../base/aquery';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable()
export class SystemConfigService extends BaseService {

    aq: AQuery;

    /**
     * {
     * "id": "S5685495992418304-byod",
        "data": {
            "P2": "V2",
            "P3": "V3",
            "translations": {
                "super": "man",
                "hello": "world"
            },
            "templateId": "e6ac2da6-6c1a-4497-b037-b08b0fbd39a6",
            "settings": {
                "batchOrder": true
            }
        },
        "type": null
     * }
     */
    systemConfig: any = {};
    systemBrandConfig: any = {};
    systemCourtConfig: any = {};
    private configStoreId: string | number;
    private configBrandId: string | number;
    private configCourtId: string | number;

    constructor(private http: HttpClient, public configs: ConfigService) {
        super();
        this.aq = new AQuery(http, configs);
    }

    get systemConfigIsEmpty(): boolean {
        return (Object.keys(this.systemConfig).length === 0) || (!this.systemConfig);
    }

    get systemBrandConfigIsEmpty(): boolean {
        return (Object.keys(this.systemBrandConfig).length === 0) || (!this.systemBrandConfig);
    }

    get systemCourtConfigIsEmpty(): boolean {
        return (Object.keys(this.systemCourtConfig).length === 0) || (!this.systemCourtConfig);
    }

    get hidePromoCodeInputInCourtLevel(){
        let res =  (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hidePromoCodeInputInCourtLevel']) || false;
        return res;
    }

    get courtShowOrderQueueInCart(): any {
        let res =  (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtShowOrderQueueInCart']) || false;
        return res;
    }
    get isBatchOrder(): boolean {
        let batchOrder = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['settings'] && this.systemConfig['data']['settings']['batchOrder']) || false;
        return batchOrder;
    }

    get menuLayout(): any {
        let layout = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['layout']) || {};
        return layout;
    }
    get upsellingMoveTop(): any {
        let layout = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['upsellingMoveTop']) || false;
        return layout;
    }
    get nameLimit(): any {
        // for ssp  name filed input should limit maximum
        let layout = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['nameLimit']) || false;
        return layout;
    }

    get enableCoupon(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableCoupon']) || false;
        return res;
    }

    get showOrderQueue(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueue']) || false;
        return res;
    }

    get showOrderQueueInCart(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueueInCart']) || false;
        return res;
    }
    get hideLoginPageRegister(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPageRegister']) || false;
        return res;
    }

    get hideMemberProfilePage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideMemberProfilePage']) || false;
        return res;
    }
    get preorderNoNeedRedirectCart(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['preorderNoNeedRedirectCart']) || false;
        return res;
    }
    get preorderShowLogin(): any {
        // prderOrder回来后 停留在homepage 并且弹出loginmodal
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['preorderShowLogin']) || false;
        return res;
    }
    get hideLoginPagePhoneInput(): any {
        let brandRes = (this.systemBrandConfig && this.systemBrandConfig['data'] && this.systemBrandConfig['data']['hideLoginPagePhoneInput']) || false;
        let storeRes = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPagePhoneInput']) || false;
        return brandRes || storeRes;
    }
    get hideLoginPageEmailInput(): any {
        let brandRes = (this.systemBrandConfig && this.systemBrandConfig['data'] && this.systemBrandConfig['data']['hideLoginPageEmailInput']) || false;
        let storeRes = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideLoginPageEmailInput']) || false;
        return brandRes || storeRes;
    }
    get hideCourtLoginPageEmailInput(): any{
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtLoginPageEmailInput']) || false;
        return res;
    }
    get pointsRewardsPromocodeStandalone (): any{
        // points\Rewards\Promocode 只能选一个
        let resCourt = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['pointsRewardsPromocodeStandalone']) || false;
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pointsRewardsPromocodeStandalone']) || false;
        return res || resCourt;
    }
    get hideCourtRegister(): any{
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtRegister']) || false;
        return res;
    }
    get checkCourtMemberInStart(): any{
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['checkCourtMemberInStart']) || false;
        return res;
    }
    get hideStampPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideStampPage']) || false;
        return res;
    }
    get suspended(): any {
        // for ssp project. when pickup mode. should see modes to hide mode-select btn
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['suspended']) || false;
        return res;
    }
    get brandStoreListUseListUi(): any {
        let brandRes = (this.systemBrandConfig && this.systemBrandConfig['data'] && this.systemBrandConfig['data']['brandStoreListUseListUi']) || false;
        let storeRes = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['brandStoreListUseListUi']) || false;
        return brandRes || storeRes;
    }
    get hideResetPassword(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideResetPassword']) || false;
        return res;
    }

    get showCurrency(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showCurrency']) || false;
        return res;
    }
    get estTimeMap(): [] {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['estTimeMap']) || [];
        return res;
    }

    get showOrderQueueInOrderStatus(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderQueueInOrderStatus']) || false;
        return res;
    }
    get useConfigPaymentRemind(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['useConfigPaymentRemind']) || {};
        return res;
    }
    get paymentRemind(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['paymentRemind']) || [];
        return res;
    }

    get showOrderStatusPageCallNo(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showOrderStatusPageCallNo']) || false;
        return res;
    }
    get hasScrollbar(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hasScrollbar']) || false;
        return res;
    }

    get showByodSummaryPageBillNo(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showByodSummaryPageBillNo']) || false;
        return res;
    }

    get hidePriceItemGridCart(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hidePriceItemGridCart']) || false;
        return res;
    }

    get preorderViewOnly(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['preorderViewOnly']) || false;
        return res;
    }
    get hideInputTableButton(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideInputTableButton']) || false;
        return res;
    }

    get advanceDeliveryDays(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['advanceDeliveryDays']) || 0;
        return res;
    }

    get advanceTakeawayDays(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['advanceTakeawayDays']) || 0;
        return res;
    }

    get advanceDineinDays(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['advanceDineinDays']) || 0;
        return res;
    }

    get enableDineinPickupTime(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableDineinPickupTime']) || false;
        return res;
    }

    get advanceHotelDays(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['advanceHotelDays']) || 0;
        return res;
    }

    get deliveryCutOffTime(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['deliveryCutOffTime']) || 0;
        return res;
    }

    get spotType(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['spotType']) || 'table';
        return res;
    }

    get pickupCutOffTime(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickupCutOffTime']) || 0;
        return res;
    }

    get hideTodayOrder(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideTodayOrder']) || false;
        return res;
    }
    get showPickupTimeWhenCheckout(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showPickupTimeWhenCheckout']) || false;
     return res;
    }
    get itemPricePrecision(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['itemPricePrecision'] && this.systemConfig['data']['itemPricePrecision'] > -1);
        if (res) {
            return this.systemConfig['data']['itemPricePrecision'];
        } else {
            return -1;
        }
    }


    get pickUpTimeInterval(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeInterval']) || false;
        if (res) {
            return this.systemConfig['data']['pickUpTimeInterval'];
        } else {
            // default
            return 15;
        }
    }

    get pickUpTimeCounter(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeCounter']) || false;
        if (res) {
            return this.systemConfig['data']['pickUpTimeCounter'];
        } else {
            // default
            return 99;
        }
    }

    get pickUpTimeRequired(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['pickUpTimeRequired']) || false;
        return res;
    }

    get hidePickUpTimeDefault(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hidePickUpTimeDefault']) || false;
        return res;
    }

    get OnlyModeInBrandStoreListPage(): any {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['OnlyModeInBrandStoreListPage']) || false;
        return res;
    }

    get showLoginInCartPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showLoginInCartPage']) || false;
        return res;
    }

    get showLoginInMultiCartPage(): any {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['showLoginInMultiCartPage']) || false;
        return res;
    }

    get showCourtTNCInCart(): any {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['showCourtTNCInCart']) || false;
        return res;
    }
    get hideWillEarn(): any {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideWillEarn']) || false;
        return res;
    }

    get enlargeStoreLocationInfoInSplashPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enlargeStoreLocationInfoInSplashPage']) || false;
        return res;
    }

    get showStorePhoneInfoInSplashPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showStorePhoneInfoInSplashPage']) || false;
        return res;
    }

    get hideOrderNoOnPaid(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideOrderNoOnPaid']) || false;
        return res;
    }

    get showStoreLocationInfoInByodSummaryPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showStoreLocationInfoInByodSummaryPage']) || false;
        return res;
    }

    get courtGuestInfoNameInput(): any {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtGuestInfoNameInput']) || false;
        return res;
    }

    get courtGuestInfoSpotInput(): any {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['courtGuestInfoSpotInput']) || false;
        return res;
    }

    get hideCourtGuestInfoPhoneInput(): any {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtGuestInfoPhoneInput']) || false;
        return res;
    }

    get hideCourtGuestInfoEmailInput(): any {
        let res = (this.systemCourtConfig && this.systemCourtConfig['data'] && this.systemCourtConfig['data']['hideCourtGuestInfoPhoneInput']) || false;
        return res;
    }

    get comboPageGroupExpand(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['comboPageGroupExpand']) || false;
        return res;
    }

    get directoryHideLogo(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['directoryHideLogo']) || false;
        return res;
    }

    get deliveryCanLogin(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['deliveryCanLogin']) || false;
        return res;
    }

    get hideReorderButton(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideReorderButton']) || false;
        return res;
    }

    get showNewStatusUi(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showNewStatusUi']) || false;
        return res;
    }

    get hideByodSummaryCallButton(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideByodSummaryCallButton']) || false;
        return res;
    }

    get hideByodSplashLogoContainer(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideByodSplashLogoContainer']) || false;
        return res;
    }

    get forceOldLayout(){
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['forceOldLayout']) || false;
        return res;
    }

    get checkItemMax(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['checkItemMax']) || false;
        return res;
    }
    get forceRedirectOrderStatus (): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['forceRedirectOrderStatus']) || false;
        return res;
    }

    get hideRemark(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideRemark']) || false;
        return res;
    }

    get tempDisableBYODTnC(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['tempDisableBYODTnC']) || false;
        return res;
    }

    get newlistLayoutInCatList(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['newlistLayoutInCatList']) || false;
        return res;
    }

    get showLogo(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showLogo']) || true;
        return true;
    }
    get hideContactless(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideContactless']) || false;
        return res;
    }

    get hideCutlery(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideCutlery']) || false;
        return res;
    }

    get usePostal(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['usePostal']) || false;
        return res;
    }

    get guestRegistration(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['guestRegistration']) || false;
        return res;
    }

    get enableFAQ(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['enableFAQ']) || false;
        return res;
    }

    get showLoginInItemGridPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showLoginInItemGridPage']) || false;
        return res;
    }

    get showMemberLoginModalInItemGridPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['showMemberLoginModalInItemGridPage']) || false;
        return res;
    }

    get acceptMarketingInPaymentPage(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['acceptMarketingInPaymentPage']) || false;
        return res;
    }

    get guestInfoNameInput(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['guestInfoNameInput']) || false;
        return res;
    }

    get disableOrderStatusPageFirebaseListening(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['disableOrderStatusPageFirebaseListening']) || false;
        return res;
    }

    get confirmMessage(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['confirmMessage']) || false;
        return res;
    }

    get placedMessage(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['placedMessage']) || false;
        return res;
    }

    get trackHint(): any {
        let res =  (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['trackHint']) || false;
        return res;
    }

    get memberPageHideOrderButton(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['memberPageHideOrderButton']) || false;
        return res;
    }

    get hideDeliveryStatusBar(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideDeliveryStatusBar']) || false;
        return res;
    }

    get hideDeliveryAddressButton(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['hideDeliveryAddressButton']) || false;
        return res;
    }

    get backToDirectory(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['backToDirectory']) || '';
        return res;
    }

    get disableChangeScheduledDate(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['disableChangeScheduledDate']) || false;
        return res;
    }

    get defaultContactless(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['defaultContactless']) || false;
        return res;
    }

    get defaultCutlery(): any {
        let res = (this.systemConfig && this.systemConfig['data'] && this.systemConfig['data']['defaultCutlery']) || false;
        return res;
    }

    /**
     * get System Config
     * @param storeId :string|number
     * @param brandId :string|number
     * @param courtId :string|number
     * @param callback (config:any)=> ()
     */
    public getSystemConfig(storeId: string | number, callback?: any) {
        if (!storeId) return;
        if (this.systemConfigIsEmpty || this.configStoreId !== storeId) {
            this.privateSystemConfig(storeId).subscribe(config => {
                this.configStoreId = storeId;
                console.log('getSystemConfig:', config);
                if (callback) callback(config);
            }, err => {
                if (callback) callback({});
            });
        } else if (callback) {
            callback(this.systemConfig);
        }
    }

    public getSystemBrandConfig(brandId: string | number, callback?: any) {
        if (!brandId) return;
        if (this.systemBrandConfigIsEmpty || this.configBrandId !== brandId) {
            this.privateSystemBrandConfig(brandId).subscribe(config => {
                this.configBrandId = brandId;
                console.log('getSystemBrandConfig:', config);
                if (callback) callback(config);
            }, err => {
                if (callback) callback({});
            });
        } else if (callback) {
            callback(this.systemBrandConfig);
        }
    }

    public getSystemCourtConfig(courtId: string | number, callback?: any) {
        if (!courtId) return;
        if (this.systemCourtConfigIsEmpty || this.configCourtId !== courtId) {
            this.privateSystemCourtConfig(courtId).subscribe(config => {
                this.configCourtId = courtId;
                console.log('getSystemCourtConfig:', config);
                if (callback) callback(config);
            }, err => {
                if (callback) callback({});
            });
        } else if (callback) {
            callback(this.systemCourtConfig);
        }
    }

    private privateSystemConfig(storeId: string | number, type = 'byod'): Observable<any> {
        let url = '/api/v1/store/config.json?type=' + type + '&storeId=' + storeId;
        let aq = this.aq;
        aq.url = url;
        return aq.getJson().map(jo => {
            let data = (jo && jo['data']) || {};
            this.systemConfig = data;
            return data;
        });
    }

    private privateSystemBrandConfig(brandId: string | number, type = 'byod'): Observable<any> {
        let url = '/api/v1/store/config.json?type=' + type + '&brandId=' + brandId;
        let aq = this.aq;
        aq.url = url;
        return aq.getJson().map(jo => {
            let data = (jo && jo['data']) || {};
            this.systemBrandConfig = data;
            return data;
        });
    }

    private privateSystemCourtConfig(courtId: string | number, type = 'byod'): Observable<any> {
        let url = '/api/v1/store/config.json?type=' + type + '&courtId=' + courtId;
        let aq = this.aq;
        aq.url = url;
        return aq.getJson().map(jo => {
            let data = (jo && jo['data']) || {};
            this.systemCourtConfig = data;
            return data;
        });
    }
}
