import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import * as Color from 'color';
import {Store} from 'aigens-ng-core';
import {Meta} from '@angular/platform-browser';

const defaults = {
    primary: '#0971CE',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#f4f5f8',
    secondaryTint: '#0971CE',
    successContrast: '#ffffff',
    successTint: '#0971CE',
    secondaryShade: '#0971CE',
    extTxt: '#494b9e',
    displayStore: 'block',
    tablenumShadow: 'none',
    tableNum: '#000000',
    startbtnBg: "#0971CE",
    startbtnActive: "#0971CE",
    startbtnShadow: "0px 0px 16px rgba(0,0,0,.22)",
    startextColor: "#ffffff",
    chipBadge: '#ffffff',
    chipNum: '#000000',
    contentBg: '#f1f1f1',
    itemlistBg: '#ffffff',
    backBtn: '#ffffff',
    toolbarBg: '#0971CE'
};

function contrast(color, ratio = 0.6) {
    color = Color(color);
    console.log('color', color);
    return color.isDark() ? color.darken(ratio) : color.lighten(ratio);
}

function CSSTextGenerator(colors) {
    if (!colors['toolbarBg'] && colors['primary']) {
        colors['toolbarBg'] = colors['primary'];
    }
    if (!colors['startbtnBg'] && colors['primary']) {
        colors['startbtnBg'] = colors['primary'];
    }
    if (!colors['startbtnActive'] && colors['primary']) {
        colors['startbtnActive'] = colors['primary'];
    }

    if (!colors['backBtn']) {
        colors['backBtn'] = defaults.backBtn;
    }
    if (!colors['successContrast']) {
        colors['successContrast'] = defaults.successContrast;
    }
    if (!colors['extTxt']) {
        colors['extTxt'] = defaults.extTxt;
    }
    if (!colors['displayStore']) {
        colors['displayStore'] = defaults.displayStore;
    }
    if (!colors['tablenumShadow']) {
        colors['tablenumShadow'] = defaults.tablenumShadow;
    }
    if (!colors['tableNum']) {
        colors['tableNum'] = defaults.tableNum;
    }

    if (!colors['startbtnShadow']) {
        colors['startbtnShadow'] = defaults.startbtnShadow;
    }
    // if (!colors['startbtnBg']) {
    //     colors['startbtnBg'] = defaults.startbtnBg;
    // }
    // if (!colors['startbtnActive']) {
    //     colors['startbtnActive'] = defaults.startbtnActive;
    // }
    if (!colors['startextColor']) {
        colors['startextColor'] = defaults.startextColor;
    }
    if (!colors['chipBadge']) {
        colors['chipBadge'] = defaults.chipBadge;
    }
    if (!colors['chipNum']) {
        colors['chipNum'] = defaults.chipNum;
    }
    if (!colors['contentBg']) {
        colors['contentBg'] = defaults.contentBg;
    }
    if (!colors['itemlistBg']) {
        colors['itemlistBg'] = defaults.itemlistBg;
    }
    if (!colors['successTint'] && colors['primary']) {
        colors['successTint'] = colors['primary'];
    }
    colors = {...defaults, ...colors};

    const {
        primary,
        secondary,
        secondaryTint,
        secondaryShade,
        tertiary,
        success,
        warning,
        danger,
        dark,
        medium,
        light,
        toolbarBg,
        successContrast,
        successTint,
        extTxt,
        displayStore,
        tablenumShadow,
        tableNum,
        startbtnShadow,
        startbtnActive,
        startbtnBg,
        startextColor,
        chipNum,
        chipBadge,
        contentBg,
        itemlistBg,
        backBtn
    } = colors;

    const shadeRatio = 0.1;
    const tintRatio = 0.1;

    return `
    --ion-color-base: ${light};
    --ion-color-contrast: ${dark};

    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: 56,128,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio)};

    --ion-color-tertiary: ${tertiary};
    --ion-color-tertiary-rgb: 44,27,100;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade:  ${Color(tertiary).darken(shadeRatio)};

    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: 56,128,255;
    --ion-color-warning-contrast: ${contrast(warning)};
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade:  ${Color(warning).darken(shadeRatio)};

    --ion-color-success: ${success};
    --ion-color-success-rgb: 56,128,255;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade:  ${Color(success).darken(shadeRatio)};

    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: 56,128,255;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade:  ${Color(danger).darken(shadeRatio)};

    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: 56,128,255;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade:  ${Color(dark).darken(shadeRatio)};

    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: 56,128,255;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade:  ${Color(medium).darken(shadeRatio)};

    --ion-color-light: ${light};
    --ion-color-light-rgb: 56,128,255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade:  ${Color(light).darken(shadeRatio)};

    --ion-color-secondary-shade: ${secondaryShade};
    --ion-color-secondary-tint: ${secondaryTint};

    --ion-color-success-contrast: ${successContrast};
    --ion-color-success-tint: ${successTint};

    --toolbar-bg: ${toolbarBg};
    --back-btn:${backBtn};
    --chip-badge:${chipBadge};
    --chip-num:${chipNum};
    --content-bg:${contentBg};
    --itemlist-bg:${itemlistBg};
    --startext-color:${startextColor};
    --startbtn-bg:${startbtnBg};
    --startbtn-active:${startbtnActive};     
    --startbtn-shadow:${startbtnShadow};
    --table-num:${tableNum};
    --tablenum-shadow:${tablenumShadow};
    --display-store: ${displayStore};
    --ext-txt:${extTxt};
`;
}

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document, public meta: Meta) {
        // storage.get('theme').then(cssText => {  // <--- GET SAVED THEME
        //     this.setGlobalCSS(cssText);
        // });
    }

    // Override all global variables with a new theme
    setStoreTheme(store: Store) {
        console.log('set theme', store.colors);
        // let overwrites = {};
        if (store.colors) {
            // let colors = store.colors;
            // // primary: store.colors['primary'],
            // //     light: store.colors['light'],
            // //     dark: store.colors['dark'],
            //  if(colors['primary'])
            //      overwrites['primary'] =
            const cssText = CSSTextGenerator(store.colors);
            this.setGlobalCSS(cssText);
            console.log('generate theme', cssText);
        }


        // this.storage.set('theme', cssText); // <--- SAVE THEME HERE
    }
    setSeo(store:Store){
        if(store.seo){
            if(store.seo.title){
                this.meta.updateTag({property:'og:title',content: store.seo.title});
                document.title = store.seo.title
            }
            if(store.seo.keywords){
                this.meta.updateTag({property:'og:keywords',content: store.seo.keywords});
            }
            if(store.seo.desc){
                this.meta.updateTag({property:'og:desc',content: store.seo.desc});
            }
        }
    }

    setFavicon(iconUrl: string) {
        const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
        link['type'] = 'image/x-icon';
        link['rel'] = 'shortcut icon';
        link['href'] = iconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    setConfigTheme(colors: any) {
        console.log('set theme', colors);
        // let overwrites = {};
        if (colors) {
            // let colors = store.colors;
            // // primary: store.colors['primary'],
            // //     light: store.colors['light'],
            // //     dark: store.colors['dark'],
            //  if(colors['primary'])
            //      overwrites['primary'] =
            const cssText = CSSTextGenerator(colors);
            this.setGlobalCSS(cssText);
            console.log('generate theme', cssText);
        }

        // this.storage.set('theme', cssText); // <--- SAVE THEME HERE
    }

    // Define a single CSS variable
    setVariable(name, value) {
        this.document.documentElement.style.setProperty(name, value);
    }

    private setGlobalCSS(css: string) {
        this.document.documentElement.style.cssText = css;
    }

}
