import {Injectable} from '@angular/core';
import {BaseService} from '../base/base-service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {MemberService} from './member.service';
import {AQuery} from '../base/aquery';
import {Observable} from 'rxjs';
import {Data, Member, Store} from 'aigens-ng-core';
import {map, share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MemberApiService extends BaseService {

    aq: AQuery;
    constructor(private http: HttpClient, public configs: ConfigService, public memberService: MemberService) {
        super();
        this.aq = new AQuery(http, configs);

    }

    login(username: string, password: string, type: string, countryCode?: string, brandId?: number): Observable<Member> {

        const url = '/api/v1/store/login.json?type=' + type;
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {'username': username, 'password': password};
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        if (brandId) {
            params['brandId'] = brandId;
        }
        aq.params = params;

        return aq.getJson().pipe(map((jo) => {
            console.log('jadd login jo:', jo);
            this.memberService.member = Data.toData(Member, jo['data']);
            return this.memberService.member;
        }));
    }
    loginCourt(username: string, password: string, type: string, countryCode?: string, courtId?: number): Observable<Member> {

        const url = '/api/v1/store/login.json?type=' + type;
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {'username': username, 'password': password};
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        if (courtId) {
            params['courtId'] = courtId;
        }
        aq.params = params;
        return aq.getJson().pipe(map((jo) => {
            console.log('jadd login jo:', jo);
            if (jo['data'].sessionId) {
                this.configs.storage.set('crmId', jo['data'].sessionId);
                this.memberService.crmId = jo['data'].sessionId;

            }
            this.memberService.member = Data.toData(Member, jo['data']);
            return this.memberService.member;
        }));
    }
    FBloginOrRegistration(facebookId: string, token: string, join: boolean = false, storeId?: number, brandId?: number, marketing?: boolean): Observable<Member> {

        const url = '/api/v1/store/flogin.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {
            facebookId: facebookId,
            token: token,
            join: join,
            brandId: brandId,
            marketing: marketing,
            storeId: storeId
        };

        if (join) {
            params['type'] = 'join';
        }

        aq.params = params;

        return aq.getJson().pipe(map((jo) => Data.toData(Member, jo['data'])));
    }

    googleLogin(googleId: string, token: string): Observable<Member> {

        const url = '/api/v1/store/glogin.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {'googleId': googleId, 'token': token};
        aq.params = params;

        return aq.getJson().pipe(map((jo) => {
            this.memberService.member = Data.toData(Member, jo['data']);
            return this.memberService.member;
        }));
    }

    requestPassword(email: string, resetPageUrl: string): Observable<any> {

        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {email: email, host: resetPageUrl, action: 'request'};
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

     sendSms(countryCallingCodes: string, phone: string, store): Observable<any> {

        const url = '/api/v1/menu/member.json?verify=before';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {countryCode: countryCallingCodes, phone: phone};
        if (store && store.brand && store.brand.crmId) {
            params['crmId'] = store.brand.crmId;
        } else if (store && store.crmId) {
            params['crmId'] = store.crmId;
        }
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    verifySms(smsCode: string, countryCallingCodes: string, phone: string): Observable<any> {

        const url = '/api/v1/menu/member/sms.json?action=verify';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {countryCode: countryCallingCodes, code: smsCode, phone: phone};
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    requestResetCode(receivedAddress: string, type: string, countryCode?: string): Observable<any> {

        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {};
        params['action'] = 'requestOtp';
        params['type'] = type;
        params[type] = receivedAddress;
        if (countryCode) {
            params['countryCode'] = countryCode;

        }

        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    verifyResetCode(type: string, receivedAddress: string, token: string, countryCode?: string): Observable<any> {

        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {};
        params['action'] = 'verifyByOtp';
        params['type'] = type;
        params[type] = receivedAddress;
        params['token'] = token;
        if (countryCode) {
            params['countryCode'] = countryCode;

        }

        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    resetPassword(password: string, confirmPassword: string, token: string): Observable<any> {

        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {};
        params['action'] = 'resetByOtp';
        params['password'] = password;
        params['confirm'] = confirmPassword;
        params['token'] = token;

        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    resetPasswordWithOldPassword(oldPassword: string, password: string, confirmPassword: string): Observable<any> {
        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        const member = this.memberService.getMember();


        const params = {};
        params['action'] = 'resetInApp';
        params['original'] = oldPassword;
        params['password'] = password;
        params['confirm'] = confirmPassword;
        if (member) {
            params['session'] = member.sessionId;
        }

        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }


    registeration(registerInfo: any): Observable<Member> {
        const url = '/api/v1/menu/member.json';

        // var params = {
        //     email: email,
        //     password: password,
        //     token: token,
        //     storeId:storeId
        // };
        const params = registerInfo;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toData(Member, jo['data'])));
    }

    join(storeId: number, brandId: number, marketing: boolean, marketingOrderPlace: boolean = false): Observable<any> {
        const url = '/api/v1/reward/activity.json';
        const member = this.memberService.getMember();
        const headers = {};
        const params = {
            brandId: brandId,
            type: 'join',
            marketing: marketing,
            storeId: storeId,
            marketingOrderPlace: marketingOrderPlace
        };


        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;

        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(jo => jo));
    }

    editProfile(registerInfo: any): Observable<Member> {
        const url = '/api/v1/menu/member.json';

        // var params = {
        //     email: email,
        //     password: password,
        //     token: token,
        //     storeId:storeId
        // };
        const params = registerInfo;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;

        return aq.auth(true).getJson().pipe(map((jo) => {
            const user = Data.toData(Member, jo['data']);
            return user;
        }));
    }

    checkingMembership(brandId: number): Observable<any> {
        const url = '/api/v1/reward/membership.json?brandId=' + brandId;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.auth(true);

        return aq.getJson().pipe(map((jo) => {
            this.memberService.hasMembership = jo['data'].length > 0;
            return jo['data'];
        }));
    }

    getRedeemableRewards(brandId: number) {
        const url = `/api/v1/reward/reward.json?type=stamp&brandId=${brandId}`;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.auth(true);

        return this.aq.getJson().pipe(share(), map((jo) => {
            return jo['data'];
        }));
    }

    redeemReward(brandId: number, rewardId: number) {
        const url = `/api/v1/reward/redeemstamps.json?rewardId=${rewardId}&brandId=${brandId}`;
        this.aq.url = url;

        this.aq.method = 'post';
        this.aq.auth(true);

        return this.aq.getJson().pipe(map((jo) => {
            return jo['data'];
        }));
    }


    // current only for crystalJade
    // login with cardNo
    loginWithCardNo(cardNo: string, brandId: string|number) {
        const url = `/api/v1/store/login.json`;
        let aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        let params = {};
        params['type'] = 'code';
        params['brandId'] = brandId;
        params['code'] = cardNo;

        aq.params = params;

        return aq.getJson().pipe(map((jo) => {
            return jo['data'];
        }));
    }
}
