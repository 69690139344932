import {Pipe, PipeTransform} from '@angular/core';
import { Item, OrderItemBuilder } from 'aigens-ng-core';

@Pipe({name: 'comboIdFilter'})
export class ComboIdFilter implements PipeTransform {
    transform(items: Item[], group, dsGmap: any, builder: OrderItemBuilder ): Item[] {
        if (group.comboId) {
            return items.filter(i => {
                let decideGoupSelectedItemsId = builder.getSelectedItems(dsGmap[group.comboId], true)[0]; // decideGroup 必定只有一个item可选
                let itemComboIds = i.comboId && i.comboId.split(',') || [];
                return itemComboIds.indexOf(decideGoupSelectedItemsId) >= 0;
                // 对应gourp 已选的item 和 items.combo match

            });
        } else {
            return items;
        }
    }
}
