import {Brand, Store} from 'aigens-ng-core';
import {TermsConditionsPage} from '../../../dialogs/terms-conditions/terms-conditions';
import {MemberService} from '../../../core/services/member.service';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {getCountryCallingCode} from '../../../shared/utilities/checkFormatTool';
import {MobileBasePage} from 'src/app/core/base/mobile-base-page';
import {AddressDialog} from 'src/app/dialogs/address-dialog/address-dialog.component';
import {NavParams} from '@ionic/angular';

declare var FB: any;

@Component({
    selector: 'app-delivery-login',
    templateUrl: './delivery-login.page.html',
    styleUrls: ['./delivery-login.page.scss'],
})
export class DeliveryLoginPage extends MobileBasePage implements OnInit, OnDestroy {

    form: any = {};

    status = '';
    fbui: any;
    brand: Brand;
    store: Store;
    loginCallBack: any;
    googleLoginClicked = false;
    googleLoginHandler: any;
    mode = 'email';

    hasCrmId = false;

    isPopup = false;
    fromPage: string;

    facebookLogin = false;
    googleLogin = false;

    public navParams;

    constructor(injector: Injector, public memberService: MemberService) {
        super(injector);

        this.loginCallBack = this.getNavParams('logincb');
        this.store = this.getNavParams('store');
        this.fromPage = this.getNavParams('fromPage');

        if (!this.store) {
            // when this component is using for modal-pop-up ,it can be injector NavParams;otherwise be err;
            this.navParams = injector.get(NavParams);
            this.store = this.navParams.get('store');
        }

        if (this.orderManager.mode === 'delivery' && !this.getNavParams('isRoute')) {
            //   when this component is not using for modal-pop-up , the next code will be err;
            this.isPopup = this.navParams.get('isPopup');
        }

        this.brand = this.store.brand;

        this.memberService.clearSession();

        if (this.brand) {
            // this.brand['crmId'] = ""
            this.hasCrmId = this.brand['crmId'] ? true : false;
            this.facebookLogin = this.brand.hasFeature('flogin');
            this.googleLogin = this.brand.hasFeature('glogin');
        }
        if (this.googleLogin) {
            this.initGoogle();
            this.googleLoginHandler = (e) => {
                const data = e.detail;
                this.googleLoginHandling(data.id, data.token);
            };
            window.addEventListener('google-login-handling', this.googleLoginHandler);
        }

        // console.log('store:', this.store);

    }

    dismissClicked() {
        console.log('delivery login modal closing');
        this.modalController.dismiss();
    }


    ngOnInit() {
        super.ngOnInit();
    }

    getCountryCallingCode() {
        return getCountryCallingCode();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        window.removeEventListener('google-login-handling', this.googleLoginHandler);
    }


    submitClicked() {

        console.log('login clicking', this.form);

        const username = (this.form[this.mode] ? this.form[this.mode] : '');
        const password = (this.form['password'] ? this.form['password'] : '');
        let countryCallingCode = this.form['countryCallingCode'];
        const tmpSet = new Set(username);
        let type = tmpSet.has('@') ? 'email' : 'phone';

        if (!countryCallingCode && type === 'phone') {
            countryCallingCode = getCountryCallingCode();
        }

        let brandId = null;
        if (this.hasCrmId) {

            if (!this.brand || !this.brand.id) {
                this.showAlert(null, 'Missing Brand ID');
                return;
            }
            if (!username) {
                this.showAlert(null, this.t.instant('pages.login.usernameEmpty'));
                return;
            }
            if (!password) {
                this.showAlert(null, this.t.instant('pages.login.passwordEmpty'));
                return;
            }

            type = 'crm';
            countryCallingCode = null;
            brandId = this.brand.id;
        }

        this.loading(true);
        this.memberApiService.login(username, password, type, countryCallingCode, brandId).subscribe(member => {

            const sessionId = member.sessionId;
            if (sessionId) {

                this.memberService.putMember(member);
                // delivery new logic
                // call api to get current member's address
                this.deliveryHandling();
                return;

                if (this.systemConfigService.hideMemberProfilePage) {
                    this.navigationService.popPage();
                } else {
                    const page = this.isProfileCompleted(member) ? 'MemberRewardPage' : 'ProfilePage';
                    const params = {isEditMode: !this.isProfileCompleted(member)};
                    this.pushByName(page, params, {replaceUrl: true}).then(() => {
                        if (!this.isProfileCompleted(member)) {
                            this.showAlert('', this.t.instant('pages.login.incomplete-profile'));
                        }
                    });
                }

            }

        }, err => {
            this.loading(false);
            console.error(err);
            this.showError(err);
        }, () => {
            this.loading(false);
        });

    }

    deliveryHandling() {
        this.addressManagerService.getAddresses(this.orderService.store).subscribe((addressArr) => {

            console.log('getAddresses #######', addressArr);
            // 可配送范围内的地址数组
            let addressArrBeyond = addressArr.filter(address => !address['beyond']);
            if (addressArrBeyond.length >= 1) {
                this.addressManagerService.findDefaultAddress().then((defautlAddress) => {
                    this.addressManagerService.saveCurrentAddress(defautlAddress);
                    this.addressManagerService.calculateAddress(defautlAddress, this.orderService.store).subscribe(result => {
                        this.loading(false);
                    }, err => {
                        this.loading(false);
                        return this.showAlert(err.status, err['error']);
                    });
                });
                
                if (this.fromPage === 'CategoryListPage' || this.fromPage === 'ItemGridScrollPage') {
                    this.backButtonClick();
                    // before forcing login , save the order message with Tourists,so need to clear the local object
                    const key = this.store.id + '.savedOrder';
                    this.configService.putPreference(key, null);
                    return;
                }
                this.popToRoot().then(() => {
                    if (this.loginCallBack) {
                        this.loginCallBack();
                    }
                });
            } else {
                let page = 'AddressDialog';
                this.pushByName(page, {
                    fromPage: 'deliveryLogin',
                    isRoute: true,
                    loginCb: () => {
                        this.loginCallBack();
                    }
                }, {}).then(() => {

                });
            }

        });
    }

    onFacebookLoginClick() {
        this.openTermsModal(this.brand, (data) => {
            this.fb(data);
        }, TermsConditionsPage, true, true);
    }

    fb(tncData: any) {
        this.loading(true);
        // FB.getLoginStatus((response) => {
        //     if(response.status === 'connected'){
        //         console.log(response);
        //         var data = response.authResponse
        //         // this.showAlert("",JSON.stringify(response));

        //         this.fbLoginHandling(data["userID"],data["accessToken"]);

        //     }else{
        FB.login((response) => {
            if (response.status === 'connected') {
                // Logged into your app and Facebook.
                const data = response.authResponse;
                this.fbLoginHandling(data['userID'], data['accessToken'], true, tncData.marketing);

            } else {
                this.loading(false);
                this.showAlert('Login Fail', '');
                // The person is not logged into this app or we are unable to tell.
            }
        }, {auth_type: 'reauthenticate'});

    }

    fbLoginHandling(fbId: string, token: string, join: boolean = false, marketing?: boolean) {
        this.memberApiService.FBloginOrRegistration(fbId, token, true, this.store.id, this.brand.id, marketing).subscribe((member) => {
            console.log(member);
            this.loading(false);

            const sessionId = member.sessionId;
            // this.showAlert("",JSON.stringify(member));
            if (sessionId) {

                this.memberService.putMember(member);

                // deal with the delivery condition with fblogin
                this.deliveryHandling();
                return;

                const page = this.isProfileCompleted(member) ? 'MemberRewardPage' : 'ProfilePage';
                const params = {isEditMode: !this.isProfileCompleted(member)};
                this.pushAndRemovePage(page, params, null, () => {
                    if (!this.isProfileCompleted(member)) {
                        this.showAlert('', this.t.instant('pages.login.incomplete-profile'));
                    }
                });
            }
        }, (err) => {
            this.loading(false);
            console.log(err);
            this.showError(err);
        });
    }


    registration() {
        this.pushByName('VerifyRegistrationPage', {fromPage: DeliveryLoginPage, store: this.store});
    }

    resetPassword() {
        let store = this.orderManager.store;
        if (!store)
            store = this.store;
        if (store && store.crm && store.crm.links && store.crm.links['forgetpw']) {
            // open in new tab
            window.open(store.crm.links['forgetpw']['url']);
        } else {
            this.pushByName('ResetPasswordPage', {username: this.form.email, isForgotPassword: true, brand: this.brand});
        }
    }

    initGoogle() {
        window['onGoogleSignIn'] = this.onSignIn;
        const params = {};
        params['id'] = 'google-login-script';
        this.configService.loadScript('https://apis.google.com/js/platform.js', () => {
        }, params);
    }


    onSignIn(googleUser) {
        const profile = googleUser.getBasicProfile();
        const id = profile.getId();
        const id_token = googleUser.getAuthResponse().id_token;

        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
        console.log('token:' + id_token);
        const evt = new CustomEvent('google-login-handling', {
            detail: {
                id: id,
                token: id_token
            }
        });

        window.dispatchEvent(evt);

    }

    clickGoogleLogin() {
        this.googleLoginClicked = true;
    }

    selectMode(type: string) {
        this.mode = type;
    }

    googleLoginHandling(id: string, token: string) {
        if (this.googleLoginClicked) {
            this.loading(true);
            this.memberApiService.googleLogin(id, token).subscribe(
                member => {
                    console.log(member);

                    const sessionId = member.sessionId;
                    if (sessionId) {

                        this.memberService.putMember(member);
                        // deal with the delivery condition with googlelogin
                        this.deliveryHandling();
                        return;

                        // console.log(this.navController.getViews());
                        this.pushAndRemovePage('MemberRewardPage', {}, null, () => {
                        });
                        // this.push(MemberRewardPage);
                    }

                },
                err => {
                    this.loading(false);
                    console.error(err);
                    this.showError(err);
                },
                () => {
                    this.loading(false);
                }
            );
        }
    }


}
