import { Injectable } from '@angular/core';
import { Address, Store, OrderItem, OrderManager } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressManagerService {

  aq: AQuery;
  addressArr: Address[];
  currentAddress: Address; // save the order using takeout-address
  couriers: any[] = [];
  currentCourier: any;
  isDelivery = true; // the switch of the delivery-feature
  isPickup: Boolean = false; // the mark of pick up

  pickupArr: any = [];
  pickupAddress: any; // save the store which will pick up to;

  note = ''; // delivery order remarks

  constructor(private http: HttpClient, public configs: ConfigService, public orderManager: OrderManager, protected configService: ConfigService) {
    this.aq = new AQuery(http, configs);
    this.addressArr = [];
  }

  getAddresses(store: Store): Observable<any> {
    const url = '/api/v1/store/address.json';
    const aq = this.aq;
    aq.url = url;
    aq.method = 'get';
    aq.auth(true);

    return aq.getJson().pipe(map((jo) => {
      let addressArr = jo['data'];
      addressArr.map((a, i) => {

        let distance = this.calDistance(a.latitude, a.longitude, store);
        console.log('距离', distance);
        addressArr[i]['beyond'] = distance > 5; // 超出5公里
      });
      this.addressArr = addressArr;
      return addressArr;
    }));
  }

  calculateAddress(address: Address, store: Store, oi?: OrderItem[], courierId?: string): Observable<any> {
    const url = '/api/v1/menu/calculate.json';
    const aq = this.aq;
    aq.url = url;
    aq.method = 'post';

    aq.auth(true);
    let params = {
      addressId: address['id'],
      phone: address['phone'],
      storeId: store['id'],
      type: 'delivery'
    };
    if (oi) params['orderItem'] = oi;
    if (courierId) params['courierId'] = courierId;
    aq.body = params;
    return aq.getJson().pipe(map((jo) => {
      if (jo['data'] && jo['data'].couriers) {
        console.log('AddressManagerService -> couriers', jo['data'].couriers);
        this.couriers = jo['data'].couriers;
        if (!courierId && this.couriers && this.couriers.length < 2) {
          // select default when only one
          this.currentCourier = this.couriers[0];
        }
      }
      return jo['data'];
    }));
  }

  postAddress(address: any): Observable<any> {
    const url = '/api/v1/store/address.json';
    const aq = this.aq;

    const params = address;
    aq.url = url;
    aq.method = 'post';
    aq.params = params;
    aq.auth(true);

    return aq.getJson().pipe(map((jo) => {

      return jo['data'];
    }));
  }

  deleteAddress(addressId): Observable<any> {
    if (!addressId) return;
    const url = `/api/v1/store/address/${addressId}.json`;

    const aq = this.aq;

    aq.url = url;
    aq.method = 'delete';
    aq.auth(true);

    return aq.getJson().pipe(map((jo) => {
      // local data update
      let index = this.addressArr.findIndex((value) => {
        if (value['id'] === addressId) { return true; }
        else { return false; }
      });
      this.addressArr.splice(index, 1);
      return jo['data'];
    }));
  }

  getAddressAmount() {
    return this.addressArr.length;
  }

  saveCurrentAddress(address) {
    this.currentAddress = address;
    this.configService.putPreference('deliveryAddress', this.currentAddress);
    console.log('saveCurrentAddress success, currentAddress:', this.currentAddress);

  }

  getCurrentAddress() {
    return this.currentAddress || this.findDefaultAddress();
  }
  calDistance(lat2, lon2, s, unit = 'K') {
    if (!s && !s.location && !s.location.latitude && !s.location.longitude) return;
    let lat1 = s.location.latitude;
    let lon1 = s.location.longitude;
    console.log('维度', lat1, lon1, lat2, lon2);
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    else {
      let radlat1 = Math.PI * lat1 / 180;
      let radlat2 = Math.PI * lat2 / 180;
      let theta = lon1 - lon2;
      let radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') { dist = dist * 1.609344; }
      if (unit === 'N') { dist = dist * 0.8684; }
      return dist;
    }
  }
  // bind the attr in address-dialog-ridio is what ,this filter function using attr is what ;
  findAddress(addr): Address {
    let arr = this.addressArr.find(address => address['id'] === addr['id']);
    return arr;
  }

  // get the local delivery address from the addressArr
  findDefaultAddress(): Promise<any> {
    return this.configService.getPreference('deliveryAddress').then((localAddress) => {
      let arr = this.addressArr.filter(address => !address['beyond']);
      if (localAddress){
        return localAddress;
      }
      return arr.length > 0 ? arr[0] : null;
    });
  }

  // pick up
  setPickup(bool: boolean) {
    this.isPickup = bool;
  }

  initPickupArr() {
    // todo call api to get pick up address
    // now hardcode current store
    this.pickupArr = [];
    this.pickupArr.push(this.orderManager.store.location);
  }

  getPickupAddress() {
    return this.isPickup ? (this.pickupAddress || null) : false;
  }

  setPickupAddress(obj) {
    this.pickupAddress = obj;
  }

  // return: if select delivery address and pick up address
  isSetAddress(): boolean {
    return this.currentAddress || this.pickupAddress;
  }

}
