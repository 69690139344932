import {MobileBasePage} from './mobile-base-page';
import {Injectable, Injector, OnInit} from '@angular/core';
import {Category, Item, ItemGroup, ModifierBuilder, OrderItem, OrderItemBuilder, OrderManager, Store} from 'aigens-ng-core';

@Injectable()
export abstract class BaseComboPage extends MobileBasePage implements OnInit {
    // setting flags:
    isAutoPickup = false;
    preselectItem = false;
    // input params
    // main item category
    category: Category;
    store: Store;

    currentOrderItem: OrderItem;
    // item groups
    groups: ItemGroup[];
    groupIdMap = {};
    builderGroupsCount = 0;
    mainItem: Item;
    itemMap = {};
    mainGroup: ItemGroup;
    total = 0;

    itemGroupMap = {};
    sameTitleItems = {};
    isTitleGroupExpand = {};
    openedTitleGroup = {};
    titles = {};

    modifierShown = false;

    modifierBuilder: ModifierBuilder;
    modifiable = false;
    missing = false;

    noNeedConfirm = {};

    // the target edit item
    edit: OrderItem;

    // preselectItem
    orderMap: Map<string, string[]> = new Map();

    // helper fields
    builder: OrderItemBuilder;

    isAutoPickupHasModifier = false;
    // TODO: make it a function?
    // auto pick groups have incomplete modifier?
    isAutoPickupModifierCompleted = false;

    defaultModifierPage: any;

    constructor(injector: Injector) {
        super(injector);
    }

    checkDifference(index, input) {
        return input['id'];
    }

    handleOrderManager() {
        if (this.multOrderManager.enableMutlOrder) {
            this.settingMultOrderManager();
        } /*else if (this.orderManager.isEmpty()) {
            this.orderManager.createOrder(this.store.id, null, null, null, this.store);
        }
        this.orderManager.settingOrderType(this.orderManager.mode);*/
    }

    // check if mainitems/auto pick up items has modifiers, should add pre-selected ones
    checkAutoPickupHasModifier(): void {

        this.isAutoPickupHasModifier = false;
        // fist check if main item is selected and check itself has modiifer or not
        if (this.builder.groups && this.builder.groups[0] && this.mainItem) {
            if (this.builder.groups[0].items[0] && this.builder.groups[0].items[0].id === this.mainItem.id) {
                console.log('this.builder.groups[0].itemGroupMap[0].id', this.builder.groups[0].items[0].id);

                for (const mgroup of this.mainItem.mgroups) {
                    if (mgroup) {
                        this.isAutoPickupHasModifier = true;
                        break;
                    }
                }
            }
        }

        if (this.isAutoPickupHasModifier) {
            return;
        }

        let mgroups = this.mainItem && this.mainItem.mgroups || [];
        this.isAutoPickupHasModifier = mgroups.length > 0;

        // second check if autopick group need modifier or not
        for (let i = 0; i < this.groups.length; i++) {
            if (this.isAutoPickGroup(this.groups[i])) {
                if (this.hasModifier(this.groups[i].items, this.itemMap, false)) {
                    this.isAutoPickupHasModifier = true;
                    return;
                }
            }
        }
    }

    // TODO: merge this with flatten

    // TODO: merge with the function above
    checkAutoPickupModifierCompleted(): any {
        console.log('checking isAutoPickupmodifiercompleted');
        let completed = true;

        console.log('this.builder.groups', this.builder.groups);
        console.log('this.mainItem', this.mainItem);
        // fist check if main item is selected and check itself has modiifer or not
        if (this.builder.groups && this.builder.groups[0] && this.mainItem) {
            if (this.builder.groups[0].items[0] && this.builder.groups[0].items[0].id === this.mainItem.id) {

                for (const mgroup of this.mainItem.mgroups) {
                    if (mgroup.min > 0) {

                        const mgroups: ItemGroup[] = this.builder.groups[0].items[0].mgroups;

                        if (mgroups) {
                            for (let i = 0; i < mgroups.length; i++) {
                                let quantities = 0;
                                for (const tmpItem of mgroups[i].items) {
                                    quantities += 1 * tmpItem.quantity;
                                }

                                if (mgroups[i].min > 0) {
                                    completed = quantities >= mgroups[i].min;
                                    if (!completed) {
                                        return;
                                    }
                                }
                            }
                        } else {
                            completed = false;
                        }
                    }
                }
            }
        }

        for (let i = 0; i < this.mainItem.groups.length; i++) {
            console.log('i', i);
            // check autopickup group with mainItem to see if it needs modifier
            if (this.isAutoPickGroup(this.mainItem.groups[i])) {
                console.log('mainitem.group', this.mainItem.groups[i]);
                for (const item of this.mainItem.groups[i].items) {
                    if (item.mgroups.length > 0) {
                        console.log('this.builder.groups', this.builder.groups);
                        console.log('this.builder.groups[i]', this.builder.groups[i + 1]);
                        let isItemMgroupMinGreaterThanZero = false;

                        if (!this.builder.groups[i + 1] || !this.builder.groups[i + 1].items || !this.builder.groups[i + 1].items[0] || !this.builder.groups[i + 1].items[0].mgroups) {
                            for (const itemMgroup of item.mgroups) {
                                if (itemMgroup.min > 0) {
                                    isItemMgroupMinGreaterThanZero = true;
                                    break;
                                }
                            }
                            if (isItemMgroupMinGreaterThanZero) {
                                completed = false;
                                return;
                            } else {
                                if (completed) {
                                    this.isAutoPickupModifierCompleted = true;
                                    return;
                                }
                            }
                        }

                        for (let j = 0; j < this.builder.groups[i + 1].items[0].mgroups.length; j++) {
                            let quantities = 0;
                            for (const tmpItem of this.builder.groups[i + 1].items[0].mgroups[j].items) {
                                quantities += 1 * tmpItem.quantity;
                            }

                            if (item.mgroups[0].min > 0) {
                                completed = quantities >= item.mgroups[0].min;

                                if (!completed) {
                                    return;
                                }
                            }
                        }
                    } else {
                        completed = true;
                    }
                }
            }
        }

        if (completed) {
            this.isAutoPickupModifierCompleted = true;
        }
        console.log('isAutoPickupModifierCompleted', this.isAutoPickupModifierCompleted);

    }

    discard(item: Item, group: ItemGroup) {
        /* if (this.quantityMap[item.id] && this.quantityMap[item.id] > 0) {
            this.quantityMap[item.id]--;
        } */

        this.builder.minusItem(null, item, this.getGroupIndex(group));
        this.builder.build();
    }


    isAutoPickGroup(group: ItemGroup, items?: Item[]): boolean {
        if (items) {
            return group.min === group.max && items.length === group.min;
        } else {
            const availableItems = group.items;
            let result =  group.min === group.max && availableItems.length === group.min
            return result;
        }
    }


    minusClicked(item: Item, group: ItemGroup) {
        console.log('minusClicked');
        // to do
        // this.googleAnalyticEventTrack("click", "minus-button");
        if(!this.checkCanMinus(item,group)) return;

        this.discard(item, group);
        // this.checkingGroupCompleted(item['groupIndex']);
        this.handleModifier();
    }

    checkCanMinus(item: Item, group: ItemGroup):boolean{
        let qua = this.getQuantity(item, group);
        return qua > item.min && group.items.length !== 1;
    }

    public pageName(): string {
        return '/multi-step-combo';
    }

    numberToFixed(number: number): number {
        if ('HKD' === this.orderService.currency) {
            return Number(number.toFixed(1));
        } else if ('CNY' === this.orderService.currency) {
            return Number(number.toFixed(1));

        } else if ('SGD' === this.orderService.currency) {
            return Number(number.toFixed(2));

        } else {
            return Number(number.toFixed(1));

        }
    }

    matchingGroupIndex(oi: OrderItem) {
        const groups = oi.groups;
        if (groups.length !== this.builderGroupsCount && groups.length - 1 <= this.groups.length) {
            this.groupIdMap = {};
            for (let i = 0; i < groups.length; i++) {
                this.groupIdMap[groups[i].id] = i;
            }

        }
        this.builderGroupsCount = groups.length;
    }

    updateSelectedModifier() {
        const oi: OrderItem = this.builder.build();

        let modifiers: Item[] = [];
        let total = 0;
        oi.groups.forEach(group => {

            const items: Item[] = group.items;

            items.forEach(item => {
                total = this.numberToFixed(total + item.price * item.quantity);
            });

            items.filter(item => item.mgroups && item.mgroups.length > 0).forEach(item => {

                const mgroups: ItemGroup[] = item.mgroups;

                mgroups.forEach(mgroup => {

                    modifiers = modifiers.concat(mgroup.items);

                });

            });

        });

        modifiers = modifiers.filter(item => item.quantity > 0);

        modifiers.forEach(item => {
            total = this.numberToFixed(total + item.price * item.quantity);
        });

        console.log('update price');
        this.total = total;
    }

    handleModifier() {
        const oi: OrderItem = this.builder.build();
        this.currentOrderItem = oi;
        const modifiable: Item[] = this.modifierBuilder.findItems(oi);
        this.modifiable = modifiable.length > 0;
        this.matchingGroupIndex(this.currentOrderItem);
        console.log('modifiable?', this.modifiable, modifiable);

        this.updateSelectedModifier();

    }

    addOrderItemMap(group: ItemGroup, item: Item) {
        if (!group || !item) {
            return;
        }
        if (!this.orderMap.has(group.id)) {
            this.orderMap.set(group.id, []);
        }
        this.orderMap.get(group.id).push(item.name);
        this.updateOrderMap(group.id);

    }

    updateOrderMap(id: string) {
        let newOrderArr = Object.assign([], this.orderMap.get(id));
        this.orderMap.set(id, newOrderArr);
    }

    groupingTitle(group: ItemGroup, items: Item[]): Item[] {
        const tmp = [];
        this.sameTitleItems[group.id] = {};
        this.titles[group.id] = [];

        for (const item of items) {
            if (item.variations && item.variations.length > 0) {
                this.titles[group.id].push(item.title);
                this.sameTitleItems[group.id][item.title] = [item].concat(item.variations);
            } else if (item.group) {
                const title = item.group;
                // if any items has sub group field
                if (this.titles[group.id].findIndex(t => title === t) === -1) {
                    // save sub group title
                    this.titles[group.id].push(title);
                }
                // push item to sub item groups under groupId.title
                if (!this.sameTitleItems[group.id][title]) {
                    this.sameTitleItems[group.id][title] = [item];
                } else {
                    this.sameTitleItems[group.id][title].push(item);
                }
                const index = tmp.findIndex(tmpItem => tmpItem.id === item.id);
                if (index !== -1) {
                    tmp.splice(index, 1);
                }
            } else {
                tmp.push(item);
            }
        }


        return tmp;

    }

    getBuilderGroup(group): ItemGroup {
        return this.builder.groups.find(g => {
            return (g !== null && g !== undefined && g.id === group.id);
        });
    }

    getSelectedItemNamesArr(group): Array<string> {


        const itemGroup: ItemGroup = this.getBuilderGroup(group);
        if (!itemGroup) {
            return [];
        }

        if (this.isNoNeedSelected(group)) {
            return [];
            // return this.t.instant('pages.item-select.no-need');
        }
        const selected = itemGroup.items;
        if (!selected) {
            return [];
        }
        let arr = [];
        selected.filter(item => item.quantity > 0).map(item => {
            let qtyPart = '';
            if (item.quantity > 1) {
                qtyPart = item.quantity + 'x ';
            }
            arr.push(qtyPart + item.name);
        });
        return arr;
    }

    getQuantity(item: Item, group: ItemGroup): number {
        return this.builder.getQuantityInGroup(this.getGroupIndex(group), item.id);
    }

    has(item: Item, group: ItemGroup): boolean {
        // console.log(item, group);

        return this.getQuantity(item, group) > 0;
    }

    totalQuantity(groupIndex: number): number {
        return this.builder.getChoices(groupIndex);
    }

    isCompleted(): boolean {
        // const groups: ItemGroup[] = this.builder.groups;
        // index 0 is main group, which must has an selected item
        for (let i = 0; i < this.groups.length; i++) {
            /*
            * Suppose now there are 5 groups and they are [A, B, C, D, E] and A is the main group,
            * this.groups is an array [B, C, D, E],
            * builder.groups is an array with groups which have selected itemGroupMap, says [A, B, C, (null), (null)]
            *
            * then we check this.groups one by one (except main group)
            * if this.groups[i] is not optional
            * builder.groups[i + 1] must has an selected item
            * if not, then it is incompleted
            */
            if (!this.isGroupCompleted(this.groups[i])) {
                return false;
            }


            if (!this.isRequiredModifierSelect(i)) {
                return false;
            }
        }
        return true;

    }

    abstract isRequiredModifierSelect(i);

    isGroupCompleted(group: ItemGroup): boolean {
        // console.log(group);
        if (!group) {
            return true;
        }
        const index = this.getGroupIndex(group);
        const quantity = this.totalQuantity(index);
        // 這個是business flow 的問題，
        // 原先設計是，optional 是用來顯示noNeed 的
        // 然後當optional == true 之後，再啟動skipable的邏輯  :
        // true -> 當item group 上 (選noNeed 或是 已選擇item 數目大於等於 ig.min 或是 直接不選 ) 也可以通過
        // false -> user 必須要選noNeed 或是已選擇item 數目大於等於 ig.min 才讓通過 

        if (quantity > 0 && (group.min <= quantity || quantity === group.max)) {
            // groups completed when selecting item and item.quantity meet the require
            return true;
        }
        if (group.optional) {
            if (group.skippable) {
                // groups completed when group.optional = true and group.skippable = true
                return true;
            } else {
                // current groups whether select noNeed
                let selectNoNeed: boolean = this.isNoNeedSelected(group);
                if (selectNoNeed) {
                    return index === -1;
                }
            }
        }
        return false;
    }

    isNoNeedSelected(group: ItemGroup): boolean {
        return this.noNeedConfirm[group.id] && this.getGroupIndex(group) === -1;
    }

    getGroupIndex(group: ItemGroup): number {
        return this.builder.groups.findIndex(g => {
            // TODO: is compared by id safe?
            return (g !== null && g !== undefined && g.id === group.id);
        });
    }

    handlingModifier(oi: OrderItem, realGroup: ItemGroup, itemMap, addHandler: any, notAddHandler: any, showLastOne: boolean = false) {
        if (!oi) {
            return;
        }
        let group = oi.groups.find((g) => g.id === realGroup.id);
        group = oi.groups.find((g) => g.id === realGroup.id);
        if (this.hasRequiredModifier(group.items)) {
            this.presentModifierPopup(this.store, oi, group, itemMap, this.defaultModifierPage, (confirm) => {
                if (confirm && confirm['data']) {
                    addHandler();

                } else {
                    notAddHandler(group);

                }
            }, showLastOne);
        } else {
            addHandler();
        }
    }

    hasModifier(items: Item[], itemMap: any, checkRequired: boolean = false): boolean {
        let required = false;
        let isAvailableMod = false;

        for (let item of items) {
            let tmpItem = itemMap[item.id];

            // console.log("checking tmpItem", tmpItem);
            if (tmpItem && tmpItem.mgroups && tmpItem.mgroups.length > 0) {

                for (let mgroup of tmpItem.mgroups) {
                    for (let mItem of mgroup.items) {
                        if (mItem && mItem.name) {
                            isAvailableMod = true;
                            break;
                        }
                    }
                }

                if (checkRequired) {
                    for (let mgroup of tmpItem.mgroups) {
                        if (mgroup.min && mgroup.min > 0) {
                            required = true;
                            break;
                        }
                    }
                } else {
                    required = true;
                    break;

                }

            }
        }
        // console.log("checking required", required);
        // console.log("checking isAvailableMod", isAvailableMod);
        return required && isAvailableMod;
    }

    hasRequiredModifier(items: Item[]): boolean {
        return this.hasModifier(items, this.itemMap, true);
    }

    getGroupMode(group: ItemGroup) {
        return group.repeat ? 'quantity' : 'single';
    }

    isCurrentGroupHasModifier(group: ItemGroup, map?: any): boolean {
        let index = this.groupIdMap[group.id];

        let g = this.currentOrderItem && this.currentOrderItem.groups ? this.currentOrderItem.groups[index] : null;
        if (g) {
            let has = this.hasModifier(g.items, this.itemMap);
            if (map) {
                map[g.id] = has;
            }
            return has;
        } else {
            return false;
        }
    }

    showModifierPopup(group: ItemGroup) {

        let oi = this.builder.build();
        let index = this.groupIdMap[group.id];
        let g = oi.groups[index];
        if (this.hasModifier(g.items, this.itemMap)) {

            this.presentModifierPopup(this.store, oi, g, this.itemMap, this.defaultModifierPage, (result) => {
                this.handleModifier();
            });

        }

    }

    preOpenTitleGroup(groupId: string, overwrite?: 1 | -1) {
        console.log('preOpenTitleGroup');
        /*const tmp = this.hasItemTitleGroup[groupId];
        if (tmp) {
            this.isTitleGroupExpand[groupId][tmp.title] = false;
            this.titleGroupClicked(tmp.title, tmp.index, groupId, isOpeningGroupCell);

        } else*/
        if (this.titles[groupId] && this.titles[groupId].length > 0) {
            for (let i = 0; i < this.titles[groupId].length; i++) {
                this.titleGroupClicked(this.titles[groupId][i], i, groupId, overwrite);
            }
        }
    }

    abstract titleGroupClicked(title: string, index: number, groupId: string, overwrite?: 1 | -1);

    abstract presentModifierPopup(store: Store, orderItem: OrderItem, group: ItemGroup, itemMap: any, page: any, handler?: any, showLastOne?: boolean);

    protected settingMultOrderManager() {
        if (this.multOrderManager.getOrderManager(this.store.id)) {
            this.orderManager = this.multOrderManager.getOrderManager(this.store.id);
        } else {
            let orderManager = new OrderManager();
            orderManager.mode = this.orderManager.mode;
            orderManager.createOrder(this.store.id, null, null, null, this.store);
            this.multOrderManager.setOrderManagerMap(orderManager, this.store.id);
            this.orderManager = this.multOrderManager.getOrderManager(this.store.id);

        }
    }

}




