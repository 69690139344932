var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { Injector } from '@angular/core';
import * as moment from 'moment';
/**
 * Generated class for the Passcode page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */
var PickupTimeDialog = /** @class */ (function (_super) {
    __extends(PickupTimeDialog, _super);
    function PickupTimeDialog(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.days = [];
        _this.hours = [];
        _this.mins = [];
        _this.now = new Date();
        _this.mapOfPeriod = {};
        _this.currentAvailablePeriod = [];
        _this.categoryPeriodMap = {};
        _this.listOfKey = [];
        _this.periodLimStart = 0;
        _this.periodLimEnd = 0;
        _this.dateDisplayMap = {};
        _this.isTodayPicker = true;
        _this.monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        _this.store = _this.navParams.get('store');
        _this.initPeriod();
        return _this;
    }
    PickupTimeDialog.prototype.ionViewDidLoad = function () {
        console.log('ionViewDidLoad PasscodeDlg');
    };
    PickupTimeDialog.prototype.dismissClicked = function () {
        console.log('dismiss clicked');
        this.headCount = null;
        this.modalController.dismiss();
    };
    PickupTimeDialog.prototype.confirmClicked = function (res) {
        console.log('dismiss clicked');
        if (res === 'now') {
            this.modalController.dismiss(null);
            return;
        }
        if (this.resDate && this.myDate) {
            var cats = this.menuManager.getAvailableCategories(this.store, this.resDate.getTime(), this.orderManager.mode === 'takeaway');
            console.log('cats', cats);
            if (cats && cats.length > 0) {
                this.modalController.dismiss(this.resDate);
            }
            else {
                this.showAlert('', 'no available items');
            }
        }
        else {
            // this.viewCtrl.dismiss(null);
            this.showAlert('', 'please choose a valid date');
        }
    };
    // submit() {
    //     console.log("date time", this.myDate);
    //     console.log("check sumbit");
    //     if(this.headCount > 0 && this.headCount < 20)
    //     {
    //         this.orderService.seats = this.headCount;
    //         this.orderService.editHeadCount().subscribe((result) => {
    //             console.log("edit headcount result", result);
    //             this.viewCtrl.dismiss(this.headCount);
    //         }, err => {
    //             this.showAlert(err.status, err["_body"]);
    //         })
    //     }else
    //     {
    //         this.showAlert("", "Invalid head count input");
    //     }
    // }
    PickupTimeDialog.prototype.dismissKeyboardHandling = function () {
        this.myInput.setFocus();
    };
    PickupTimeDialog.prototype.pickDate = function (date) {
        this.myDate = null;
        if (date === '0') {
            this.isTodayPicker = true;
        }
        else {
            this.isTodayPicker = false;
        }
        switch (date) {
            case '0': {
                this.chooseOtp = this.todayOpt;
                break;
            }
            case '1': {
                this.chooseOtp = this.tmrOtp;
                break;
            }
            case '2': {
                this.chooseOtp = this.dayAfterTmrOpt;
                break;
            }
        }
    };
    PickupTimeDialog.prototype.dateSetting = function (end) {
        var current = new Date();
        var b = new Date(new Date().setMinutes(new Date().getMinutes() + 45));
        // let a = new Date(new Date(end).setDate(new Date(end).getDate()+3));
        var a = new Date(new Date().setDate(new Date().getDate() + 3));
        var d1 = new Date(new Date().setDate(new Date().getDate() + 1));
        var d2 = new Date(new Date().setDate(new Date().getDate() + 2));
        var max = new Date(a);
        var min = new Date(b);
        this.todayOpt = b;
        this.tmrOtp = d1;
        this.dayAfterTmrOpt = d2;
        this.todayOptDisplay = new Date(this.todayOpt.getTime() - (this.todayOpt.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        this.tmrOtpDisplay = new Date(this.tmrOtp.getTime() - (this.tmrOtp.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        this.dayAfterTmrOptDisplay = new Date(this.dayAfterTmrOpt.getTime() - (this.dayAfterTmrOpt.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        // console.log('todayOptDisplay', this.todayOptDisplay, this.monthNames[b.getMonth()], b.getFullYear(),b.getDate());
        this.dateDisplayMap['0month'] = this.monthNames[this.todayOpt.getMonth()] + ' / ' + this.todayOpt.getFullYear();
        this.dateDisplayMap['0day'] = this.todayOpt.getDate() < 10 ? '0' + this.todayOpt.getDate() : this.todayOpt.getDate();
        this.dateDisplayMap['1month'] = this.monthNames[this.tmrOtp.getMonth()] + ' / ' + this.tmrOtp.getFullYear();
        this.dateDisplayMap['1day'] = this.tmrOtp.getDate() < 10 ? '0' + this.tmrOtp.getDate() : this.tmrOtp.getDate();
        this.dateDisplayMap['2month'] = this.monthNames[this.dayAfterTmrOpt.getMonth()] + ' / ' + this.dayAfterTmrOpt.getFullYear();
        this.dateDisplayMap['2day'] = this.dayAfterTmrOpt.getDate() < 10 ? '0' + this.dayAfterTmrOpt.getDate() : this.dayAfterTmrOpt.getDate();
        this.nowMax = new Date(max.getTime() - (max.getTimezoneOffset() * 60000)).toISOString();
        this.nowMin = new Date(min.getTime() - (min.getTimezoneOffset() * 60000)).toISOString();
        console.log('nowMax', this.nowMax);
        console.log('nowMin', this.nowMin);
        this.pickDate('0');
    };
    PickupTimeDialog.prototype.updateMyDate = function (ev) {
        console.log('ev', ev);
        var date = new Date();
        // date.setDate(ev.day);
        date = this.chooseOtp;
        date.setHours(ev.hour);
        date.setMinutes(ev.minute);
        // date.setMonth(ev.month - 1);
        console.log('date', date, date.getTime(), new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString());
        this.resDate = date;
    };
    PickupTimeDialog.prototype.initPeriod = function () {
        // inti period
        if (this.store.menu.periods) {
            var periods = this.store.menu.periods;
            for (var _i = 0, periods_1 = periods; _i < periods_1.length; _i++) {
                var period = periods_1[_i];
                if (this.isAvailablePeriod(this.store, period, new Date())) {
                    this.currentAvailablePeriod.push(period.code);
                }
                if (!period.start || !period.end) {
                    continue;
                }
                else {
                    var startTime = period.startTime;
                    var endTime = period.endTime;
                    var time = new Date().getTime();
                    var start = moment(startTime, 'HH:mm');
                    var end = moment(endTime, 'HH:mm');
                    console.log('start', start);
                    console.log('end', end);
                    var now = moment(time);
                    start.year(now.year());
                    start.month(now.month());
                    start.dayOfYear(now.dayOfYear());
                    end.year(now.year());
                    end.month(now.month());
                    end.dayOfYear(now.dayOfYear());
                    if (end < start) {
                        console.log('stepped over time to next day!!!');
                        if (now > end) {
                            end = end.add(24, 'hours');
                        }
                        else {
                            start = start.add(-24, 'hours');
                        }
                    }
                    console.log('start', start.valueOf());
                    console.log('end', end.valueOf());
                    console.log('now', now.valueOf());
                    if (now > end) {
                        console.log('wont show', period.code);
                    }
                    else {
                        if (this.periodLimStart === 0 || this.periodLimStart.valueOf() > start.valueOf()) {
                            // this.periodLimStart = start.valueOf();
                            this.periodLimStart = start;
                        }
                        if (this.periodLimEnd === 0 || this.periodLimEnd.valueOf() < end.valueOf()) {
                            // this.periodLimEnd = end.valueOf();
                            this.periodLimEnd = end;
                        }
                        this.mapOfPeriod[period.code + 'start'] = start;
                        this.mapOfPeriod[period.code + 'end'] = end;
                        var cats = null;
                        // if (this.currentAvailablePeriod.indexOf(period.code) >= 0) {
                        //     cats = this.menuManger.getAvailableCategories(this.store, new Date().getTime(), this.orderManager.mode === 'takeaway');
                        // } else {
                        //     cats = this.menuManger.getAvailableCategories(this.store, start.valueOf(), this.orderManager.mode === 'takeaway');
                        // }
                        if (cats) {
                            this.categoryPeriodMap[period.code] = cats;
                        }
                    }
                }
            }
            console.log(this.periodLimStart);
            console.log(this.periodLimEnd);
            this.listOfKey = Object.keys(this.mapOfPeriod);
            console.log('this.categoryPeriodMap[period.code]', this.categoryPeriodMap);
            this.dateSetting(this.periodLimEnd.valueOf());
        }
    };
    return PickupTimeDialog;
}(MobileBasePage));
export { PickupTimeDialog };
