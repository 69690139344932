var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OpeningHours, Period, Slot } from 'aigens-ng-core';
import * as assert from 'assert';
var CountryCode = /** @class */ (function () {
    function CountryCode() {
    }
    return CountryCode;
}());
/** to form logic  start */
export var defaultCountryCodes = [
    { country: 'US/CA', code: '1', length: 10, regex: /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ },
    { country: 'NL', code: '31' },
    { country: 'BE', code: '32' },
    { country: 'FR', code: '33', length: '7-14' },
    { country: 'ES', code: '34' },
    { country: 'UK', code: '44' },
    { country: 'DE', code: '49' },
    { country: 'MY', code: '60', length: 10, regex: /^0?(1[1,5]?\d{8}|[4-7,9]\d{7}|8[2-9]\d{6}|3\d{8})$/ },
    { country: 'AU', code: '61', length: '9-10' },
    { country: 'ID', code: '62', length: '8-12', regex: /^(0|8)\d{2}\d{6,9}/ },
    { country: 'PH', code: '63', regex: /^09[0-9]{9}$|^0?2[0-9]{7}$|^0?32[0-9]{7}$/ },
    { country: 'SG', code: '65', length: 8, regex: /^[689]{1}[0-9]{7}$/ },
    { country: 'TH', code: '66', length: 9 },
    { country: 'VN', code: '84', regex: /^0?(2|[35789])[0-9]{8}$|^02[48][0-9]{8}$/ },
    { country: 'HK', code: '852', length: 8, regex: /^((?!999)([2-9][0-9]{7}))$/ },
    { country: 'MO', code: '853', length: 8 },
    { country: 'CN', code: '86', length: 11 },
    { country: 'TW', code: '886', length: 10, regex: /^0([1-8]{1}[0-9]{7,8}|9[0-9]{8})$/ },
    { country: 'ID', code: '91', regex: /^([6-9][0-9]{9}|22[0-9]{8})$/ }
];
// 8-12 letters and numbers
export function passwordFormatValidator(control) {
    return isPasswordVaild(control.value) ? null : { 'invalid password format': 'password invaild' };
}
export function confirmPasswordFormatValidator(control) {
    var input = control.value;
    var isValid = control.root.value['Password'] === input;
    if (!isValid) {
        return { 'equalTo': { isValid: isValid } };
    }
    else {
        return null;
    }
}
export function isPasswordVaild(value) {
    var isVaild = false;
    if (value) {
        isVaild = ((/[a-z]/.test(value) && /[0-9]/.test(value)) || (/[0-9]/.test(value) && /[A-Z]/.test(value)));
    }
    return isVaild;
}
// by '852' number-string return 'hk'
export function findCountryByCodes(value) {
    var key;
    for (var k in defaultCountryCodes) {
        if (defaultCountryCodes[k].code === value) {
            key = defaultCountryCodes[k].country;
            break;
        }
    }
    return key || 'HK';
}
export function phoneValidator(control) {
    if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
    }
    return isPhoneNumberValid(control.value.toString()) ? null : { 'mobile phone invalid': 'phone number invalid' };
}
export function isEmptyInputValue(value) {
    return value == null || (typeof value === 'string' && value.length === 0) || value === undefined;
}
export function isPhoneNumberValid(value) {
    var isValid = false;
    if (value !== undefined) {
        // 不能以 0/1 開頭
        // isValid = value.length == 8 && /^([2-9])\d{7}/.test(value);
        var newValue = value.trim();
        if (newValue) {
            isValid = newValue.length > 7 && /^\d+$/.test(newValue);
        }
        else {
            isValid = value.length > 7 && /^\d+$/.test(value);
        }
    }
    return isValid;
}
export function isPhoneValid(phone, country) {
    if (country === void 0) { country = 'HK'; }
    var isValid = false;
    var entry = defaultCountryCodes.find(function (i) { return i.country.indexOf(country) !== -1 || i.code.indexOf(country) !== -1; });
    if (entry && entry.regex) { // 若harcode有国家的phone正则， 则使用它
        return entry.regex.test(phone);
    }
    if (!onlyHasNumber(phone)) {
        return false;
    }
    if (phone !== undefined && phone !== '') {
        var length_1 = entry.length;
        var _a = __assign({}, String(length_1).split('-')), min = _a[0], max = _a[1];
        if (max) {
            if (phone.length >= Number(min) && phone.length <= Number(max)) {
                isValid = true;
            }
        }
        else {
            if (phone.length === length_1)
                isValid = true;
            else {
                // 当号码有前缀也通过 比如说 85244090771 而不只是 44090771
                var countryCode = phone.slice(0, phone.length - Number(length_1));
                isValid = phone.length === (entry.code.length + Number(length_1)) && countryCode === getCountryCallingCode(country);
            }
        }
    }
    return isValid;
}
function matchLength(input, match) {
    if (typeof match === 'number') {
        return input === match;
    }
    else {
        assert(typeof match === 'string');
        var _a = __assign({}, match.split('-')).map(function (i) { return Number(i); }), min = _a[0], max = _a[1];
        return input >= min && input <= max;
    }
}
// 前提是通过电话验证 isPhoneValid()
export function isPhoneIncludingCode(phone, country) {
    if (country === void 0) { country = 'HK'; }
    var isSeparate = false; // 是否分隔
    var isIncludingCode = false; // 是否has country code
    var separateSymbol = null; // 分隔符号
    var noCodePhone = '';
    if (isPhoneValid(phone, country)) {
        if (matchLength(phone.length, getPhoneLength(country))) {
            return { isSeparate: isSeparate, isIncludingCode: isIncludingCode, separateSymbol: separateSymbol, noCodePhone: phone };
        }
        else {
            // including code
            if (matchLength(phone.length - getCountryCallingCode(country).length, getPhoneLength(country))) {
                // not has separateSymbol
                isIncludingCode = true;
                noCodePhone = phone.slice(getCountryCallingCode(country).length);
                return { isSeparate: isSeparate, isIncludingCode: isIncludingCode, separateSymbol: separateSymbol, noCodePhone: noCodePhone };
            }
            else {
                // has separateSymbol
                isIncludingCode = true;
                isSeparate = true;
                separateSymbol = phone.slice(getCountryCallingCode(country).length, getCountryCallingCode(country).length + 1);
                noCodePhone = phone.slice(getCountryCallingCode(country).length + 1);
                return { isSeparate: isSeparate, isIncludingCode: isIncludingCode, separateSymbol: separateSymbol, noCodePhone: noCodePhone };
            }
        }
    }
    else {
        return {};
    }
}
function test(value) {
    value = value.toLowerCase();
    var isValid = false;
    if (value !== undefined) {
        var regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        //    isVaild = value.includes("@") && (value.includes(".com") || value.includes(".net"));
        isValid = regExp.test(value) && (!value.includes('.web')) && (!value.includes('..')) && value.indexOf('.') !== 0;
        isValid = isValid && !(value.includes('<') && value.indexOf('>') === value.length - 1) &&
            value.indexOf('@') === value.lastIndexOf('@') && value.indexOf(')') !== value.length - 1;
        isValid = isValid && !(/^\d+$/.test(value[value.length - 1]) && (value.length - 1 - value.lastIndexOf('.') >= 4));
    }
    return isValid;
}
export function isEmailValid(value) {
    try {
        value = value.toLowerCase();
        var isValid = false;
        if (value !== undefined) {
            var regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            //    isVaild = value.includes("@") && (value.includes(".com") || value.includes(".net"));
            isValid = regExp.test(value) && (!value.includes('.web')) && (!value.includes('..')) && value.indexOf('.') !== 0;
            isValid = isValid && !(value.includes('<') && value.indexOf('>') === value.length - 1) &&
                value.indexOf('@') === value.lastIndexOf('@') && value.indexOf(')') !== value.length - 1;
            isValid = isValid && !(/^\d+$/.test(value[value.length - 1]) && (value.length - 1 - value.lastIndexOf('.') >= 4));
        }
        return isValid;
    }
    catch (err) {
        return false;
    }
}
export function isNameValid(value) {
    return validateName(value);
}
export function emailFormatValidator(control) {
    return isEmailValid(control.value) ? null : { 'email format': 'email invaild' };
}
// let defulatLangs = ['en', 'zh', 'zh-cn'];
export function firstNameFormatValidator(control, errorText) {
    return validateName(control.value) ? null : { 'first name format': errorText || 'Please enter name in First Name, First Name format' };
}
export function lastNameFormatValidator(control, errorText) {
    return validateName(control.value) ? null : { 'last name format': errorText || 'Please enter name in Last Name, Last Name formate' };
}
export function isNameVaild(username) {
    var isVaild = false;
    if (username) {
        isVaild = /^[A-Za-z\s]+$/.test(username);
    }
    else {
        isVaild = true;
    }
    return isVaild;
}
export function validateName(username) {
    var name = new RegExp('^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z_]| ){1,20}$'); // 不包含“-”
    if (username != null || typeof username === 'undefined' || !username) {
        return name.test(username);
    }
    else {
        return false;
    }
}
export function onlyHasNumber(string) {
    var regExp = /^\d+$/;
    return regExp.test(string);
}
// by country return '852': number-string
export function getCountryCallingCode(country) {
    if (country === void 0) { country = 'HK'; }
    var tmp = defaultCountryCodes.find(function (i) { return i.country.indexOf(country) !== -1; });
    if (!tmp) {
        return '852';
    }
    return tmp.code;
}
export function getPhoneLength(country) {
    return defaultCountryCodes.find(function (i) { return i.country.indexOf(country) !== -1; }).length;
}
export function isUnitNumberFormat(string) {
    var regExp = /[^A-Za-z0-9 /-]/;
    var onlyNumberAndLetterAndSpace;
    onlyNumberAndLetterAndSpace = !regExp.test(string);
    return onlyNumberAndLetterAndSpace;
}
export function asLetter(string) {
    var regExp = /[A-Za-z]/;
    return regExp.test(string);
}
export function hasNumber(string) {
    var regExp = /[0-9]/;
    return regExp.test(string);
}
export function getDateStringLabel(milliseconds) {
    var date = new Date(milliseconds);
    var timeString = date.getFullYear().toString() + '-';
    if ((date.getMonth() + 1) <= 9) {
        timeString = timeString + '0' + (date.getMonth() + 1).toString() + '-';
    }
    else {
        timeString = timeString + (date.getMonth() + 1).toString() + '-';
    }
    if (date.getDate() <= 9) {
        timeString = timeString + '0' + date.getDate().toString();
    }
    else {
        timeString = timeString + date.getDate().toString();
    }
    return timeString;
}
export function currentTimeString() {
    return timeStringLabel(null, true);
}
export function timeStringLabel(pickUpTime, withSecond) {
    if (withSecond === void 0) { withSecond = false; }
    var date;
    if (pickUpTime) {
        date = new Date(pickUpTime);
    }
    else {
        date = new Date();
    }
    var timeString = date.getHours() + ':';
    if (date.getMinutes() === 0) {
        timeString = timeString + '00';
    }
    else if (date.getMinutes() <= 9) {
        timeString = timeString + '0' + date.getMinutes();
    }
    else {
        timeString = timeString + date.getMinutes();
    }
    if (withSecond) {
        timeString = timeString + ':';
        if (date.getSeconds() === 0) {
            timeString = timeString + '00';
        }
        else if (date.getSeconds() <= 9) {
            timeString = timeString + '0' + date.getSeconds();
        }
        else {
            timeString = timeString + date.getSeconds();
        }
    }
    return timeString;
}
export function getClosedTime(store) {
    var storeCloseTime;
    var today = new Date();
    if (store.openings && store.openings['weekdays']) {
        var weekday = today.getDay();
        storeCloseTime = store.openings['weekdays'][weekday]['endTime'];
    }
    if (!storeCloseTime) {
        return '24:00';
    }
    return storeCloseTime;
}
export function getPickupDelayTime(store) {
    if (!store)
        return 0;
    if (!store.pos) {
        return 0;
    }
    return Number(store.pos['pickupDelay']);
}
export function getDeliveryDelayTime(store) {
    if (!store)
        return 0;
    if (!store.pos) {
        return 0;
    }
    return Number(store.pos['deliveryDelay']);
}
export function getTodayMillisecondsFromTimeString(timeLabel) {
    var date = new Date();
    if (!isNaN(Number(timeLabel.split(':')[0]))) {
        if (timeLabel.split(':').length === 2) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), 0, 0);
        }
        else if (timeLabel.split(':').length === 3) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), Number(timeLabel.split(':')[2]), 0);
        }
        else if (timeLabel.split(':').length === 4) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), Number(timeLabel.split(':')[2]), Number(timeLabel.split(':')[3]));
        }
    }
    else {
        return 0;
    }
    return Number(timeLabel.split(':')[0]) === 0 ? (date.getTime() + (24 * 60 * 60000)) : date.getTime();
}
export function isAvailableOffer(offer) {
    var current = new Date().getTime();
    var start = offer.start ? offer.start : current;
    var end = offer.expire ? offer.expire : current;
    return start <= current && end >= current;
}
export function isAvailableReward(reward) {
    var current = new Date().getTime();
    var start = reward.start ? reward.start : current;
    var end = reward.end ? reward.end : current;
    return start <= current && end >= current;
}
export function isAvailablePeriod(store, period, now) {
    // var today: Date = new Date();
    // today.setHours(0, 0, 0, 0);
    // var diff = now.getTime() - today.getTime();
    // var start:number;
    // var end:number;
    // //console.log("diff", diff);
    // if(period.openings && period.openings.weekdays){
    //     var [dateStart,startString,dateEnd,endString] = this.getWeekdayStartAndEnd(period);
    //     start = dateStart.getTime() - today.getTime();
    //     end = dateEnd.getTime() - today.getTime();
    // }else{
    //     if (!period.start || !period.end) return false;
    //     start = period.start;
    //     end = period.end;
    // }
    // return diff >= start && diff < end;
    if (period.openings) {
        console.log('isAvailable : check by opening rules');
        var open_1 = OpeningHours.isTimeOpen(store.brand.openings, period.openings, now.getTime());
        return open_1;
    }
    else {
        // console.log("diff", diff);
        if ((typeof period.start) === 'undefined' || (typeof period.end) === 'undefined') {
            return false;
        }
        console.log('isAvailable : check by start & end');
        return Period.isTimeOpen(period, now.getTime());
    }
}
export function getWeekdayStartAndEnd(period) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var weekday = period['savedWeekday'] !== null ? period['savedWeekday'] : today.getDay();
    var weekdays = period.openings.weekdays;
    var start = new Date();
    var end = new Date();
    var startTime;
    var endTime;
    if (weekdays[weekday]['startTime'] && weekdays[weekday]['endTime']) {
        startTime = weekdays[weekday]['startTime'];
        endTime = weekdays[weekday]['endTime'];
    }
    else {
        startTime = period.startTime;
        endTime = period.endTime;
    }
    start.setHours(Number(startTime.split(':')[0]), Number(startTime.split(':')[1]), 0, 0);
    end.setHours(Number(endTime.split(':')[0]), Number(endTime.split(':')[1]), 0, 0);
    if (today.getDay() !== weekday) {
        if (end < start) {
            start = new Date(start.getTime() - 24 * 60 * 60000);
        }
        else {
            start = new Date(start.getTime() - 24 * 60 * 60000);
            end = new Date(end.getTime() - 24 * 60 * 60000);
        }
    }
    else {
        if (end < start) {
            console.log('stepped over time to next day!!!');
            end = new Date(end.getTime() + 24 * 60 * 60000);
        }
    }
    return [start, startTime, end, endTime];
}
export function hasHoliday(brand, oh, time) {
    if (time === void 0) { time = new Date().getTime(); }
    if (!oh) {
        return null;
    }
    if (oh.overwrites) {
        for (var _i = 0, _a = oh.overwrites; _i < _a.length; _i++) {
            var slot = _a[_i];
            if (Slot.isWithinDayRange(slot, time)) {
                console.log('within overwrites', slot);
                return slot;
            }
        }
    }
    if (brand.openings) {
        if (brand.openings.ph1) {
            for (var _b = 0, _c = brand.openings.ph1; _b < _c.length; _b++) {
                var slot = _c[_b];
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log('within holiday1', slot);
                    return oh.holidays[0];
                }
            }
        }
        if (brand.openings.ph2) {
            for (var _d = 0, _e = brand.openings.ph2; _d < _e.length; _d++) {
                var slot = _e[_d];
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log('within holiday2', slot);
                    return oh.holidays[1];
                }
            }
        }
    }
    return null;
}
export function isPeriodClosed(store, period, currentTime) {
    if (!period) {
        return false;
    }
    else {
        var preStop = period['prestop'] ? period['prestop'] : 0;
        if (preStop === 1440) {
            return true;
        }
        var endTime = new Date();
        var holidaySlot = hasHoliday(store.brand, period.openings);
        if (holidaySlot) {
            var startTime = new Date();
            // endTime.setHours(Number(holidaySlot.endTime.split(":")[0]), Number(holidaySlot.endTime.split(":")[1]));
            startTime.setHours(Number(holidaySlot.startTime.split(':')[0]), Number(holidaySlot.startTime.split(':')[1]), 0, 0);
            endTime.setHours(Number(holidaySlot.endTime.split(':')[0]), Number(holidaySlot.endTime.split(':')[1]), 0, 0);
            if (endTime < startTime) {
                console.log('stepped over time to next day!!!');
                if (new Date() > endTime) {
                    endTime = new Date(endTime.getTime() + 24 * 60 * 60000);
                }
            }
        }
        else if (period.openings && period.openings.weekdays) {
            var _a = getWeekdayStartAndEnd(period), dateStart = _a[0], startString = _a[1], dateEnd = _a[2], endString = _a[3];
            endTime = dateEnd;
        }
        else {
            var startTime = new Date();
            startTime.setHours(Number(period.startTime.split(':')[0]), Number(period.startTime.split(':')[1]), 0, 0);
            endTime.setHours(Number(period.endTime.split(':')[0]), Number(period.endTime.split(':')[1]), 0, 0);
            if (endTime < startTime) {
                console.log('stepped over time to next day!!!');
                if (new Date() > endTime) {
                    endTime = new Date(endTime.getTime() + 24 * 60 * 60000);
                }
            }
        }
        console.log('endTime:' + (endTime.getTime() - preStop * 60000));
        console.log('currentTime:' + currentTime);
        return (endTime.getTime() - preStop * 60000) < currentTime;
    }
}
export function getTandCUrl(brand, name) {
    if (!brand.links) {
        return null;
    }
    var link = brand.links[name];
    if (!link) {
        return null;
    }
    var url = link.url;
    if (!url) {
        return null;
    }
    if (url.indexOf('http://') === 0 && url.indexOf('localhost') === -1) {
        url = url.replace('http:', 'https:');
    }
    return url;
}
/** to form logic  end */
