import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboPage } from './combo-page';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {EnlargePage} from './enlargePage/enlargePage';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [ComboPage, EnlargePage],
  entryComponents: [ComboPage, EnlargePage],
})
export class ComboPageModule { }
