<!-- <ion-header no-border>
    <ion-toolbar color="background-color">
        <ion-buttons slot="start">
            <ion-button appThrottleClick (throttleClick)="backButtonClick()" class="back-btn AQA-back-btn">
                <ion-icon name="arrow-back"></ion-icon>
                <p ion-text color="dark">{{"global.previous" | translate}}</p>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header> -->


<ion-header class="order-header" *ngIf="!isPopup">
    <ion-toolbar>
        <ion-buttons slot="start">
            <custom-back-button [forceHideText]="true"
                                (backButtonClick)="backButtonClick()"></custom-back-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'isModalPopup': isPopup}">
    <p class="page-title">{{"pages.login.title" | translate}}</p>

    <!-- delivery modal el of close-btn -->
    <div *ngIf="isPopup">
        <ion-button slot="end" (click)="dismissClicked()">
            <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon>
        </ion-button>
    </div>


    <div class="cus-segment-wrapper">
        <ion-button fill="clear" disable-hover (click)="selectMode('email')" [ngClass]="{'isActive': mode == 'email'}">
            <!-- <ion-icon name="ios-mail-outline"></ion-icon> -->
            {{"pages.login.email" | translate}}
        </ion-button>
        <ion-button *ngIf="!systemConfigService.hideLoginPagePhoneInput" fill="clear" disable-hover
                    (click)="selectMode('phone')" [ngClass]="{'isActive': mode == 'phone'}">
            <!-- <ion-icon name="ios-mail-outline"></ion-icon> -->
            {{"pages.login.phone" | translate}}
        </ion-button>
    </div>
    <form (ngSubmit)="submitClicked()" class="f-input" padding>
        <div class="form-box">
            <ng-container *ngIf="mode == 'phone'">
                <div class="phone-input-box animated fadeInUps">
                    <ion-label position="stacked" class="std-input-label">{{"pages.login.phone" |
                        translate}}</ion-label>
                    <ion-item class="phone-box std-input-item">
                        <ion-input class="std-input" type="tel" clearInput="true" name="countryCallingCode"
                                   [(ngModel)]="form.countryCallingCode"
                                   placeholder="{{getCountryCallingCode()}}"></ion-input>
                        <ion-input class="std-input" type="tel" clearInput="true" name="phone" [(ngModel)]="form.phone">
                        </ion-input>
                    </ion-item>
                </div>
            </ng-container>

            <ng-container *ngIf="mode == 'email'">
                <ion-item class="std-input-item animated fadeInUps">
                    <ion-label position="stacked" class="std-input-label">{{"pages.login.email" |
                        translate}}</ion-label>
                    <ion-input type="email" clearInput="true" name="email" class="std-input" [(ngModel)]="form.email">
                    </ion-input>
                </ion-item>
            </ng-container>

            <ion-item class="std-input-item animated fadeInUps">
                <ion-label position="stacked" class="std-input-label">{{"pages.login.password" |
                    translate}}</ion-label>
                <ion-input [(ngModel)]="form.password" type="password" name="password" minlength="1" required
                           class="std-input" (keyup.enter)="submitClicked();$event.stopPropagation();"></ion-input>
            </ion-item>

            <div class="login-feature-box">
                <h6 size="small" fill="clear" color="primary" (click)="resetPassword()" class="reset-pw-btn">
                    {{"pages.login.reset-password" | translate}}
                </h6>
            </div>
        </div>

        <div text-center class="btn fill-btn">
            <div class="flex-row">
                <ion-button *ngIf="!systemConfigService.hideLoginPageRegister" expand="block" (click)="registration()"
                            color="primary">
                    <span>{{"pages.login.register" | translate}}</span>
                </ion-button>
                <ion-button fill="outline" type="submit" color="primary" expand="block">
                    {{"pages.login.login" | translate}}</ion-button>
            </div>
        </div>
    </form>
    <!-- <div class="btn-box">
        <p style="text-align: center;">{{"pages.login.no-account" | translate}}</p>
        <ion-button expand="block" (click)="registration()" color="success">
            <span>{{"pages.login.register" | translate}}</span>
        </ion-button>
    </div> -->
    <div *ngIf="facebookLogin || googleLogin" class="social-login-btn-wrapper">
        <p class="page-title">{{"pages.login.social-media-title" | translate}}</p>
        <div class="social-login-btn-box">

            <button *ngIf="facebookLogin" class="social-login-btn" (click)="onFacebookLoginClick()">
                <img src="../assets/images/pizzahut/facebook.svg">
            </button>
            <!---->
            <button *ngIf="googleLogin" class="social-login-btn g-signin2"
                    data-onsuccess="onGoogleSignIn" data-theme="dark" (click)="clickGoogleLogin()">
                <img src="../assets/images/pizzahut/google.svg">
            </button>
            <!-- <button class="social-login-btn">
                <img src="../assets/images/pizzahut/wechat.svg">
            </button> -->

        </div>
    </div>

</ion-content>


<!-- <ion-footer>
  <div>
      <p class="page-title">{{"pages.login.social-media-title" | translate}}</p>
  </div>
</ion-footer> -->
