<ion-content class="dine-counter">

    <div class="top-container bg-remove">
        <!--bg-remove:remove the item list background-->
        <div class="rec-header"></div>

        <!--<div class="sp-req-header">
            <div class="header-counter">
                <span class="header-line-01"></span>
                <span class="header-line-02"></span>
                <h1> Recommendation </h1>
            </div>
        </div>-->

        <div class="content-container">
            <h4 class="sp-sub-title">
                <!--                <h1> {{'pages.unlock-popup.title' | translate}} </h1>-->
                <h1> {{category?.name ? category?.name : ('pages.unlock-popup.title' | translate)}} </h1>
                <span></span>
                <p *ngIf="max">{{displayTitle}}</p>
            </h4>


            <div class="sp-req-content">

                <div class="sp-req-grid" *ngIf="!showImage && max == 1">

                    <div col-12 class="sp-req-item" *ngFor="let item of addItems; let i = index"
                         [hidden]="item.parentId && !isParent(item)">
                        <div class="btn-sp-req" [ngClass]="{'stay' :qtyTag[i] > 0}" (click)="addClicked(i)">
                            <ion-icon name="md-checkmark" class="sp-req-tick"></ion-icon>
                            <p>{{item.name}}</p>
                            <span class="sp-req-price">{{store.sign}}{{item.price}}</span>
                        </div>
                    </div>

                </div>

                <div class="sp-req-grid" *ngIf="!showImage && max > 1">

                    <div col-12 class="sp-req-item" *ngFor="let item of addItems; let i = index"
                         [hidden]="item.parentId && !isParent(item)">
                        <div class="btn-sp-req stay" (click)="addClicked(i)">

                            <p>{{item.name}}</p>
                            <span class="sp-req-price">{{store.sign}}{{item.price}}</span>
                            <div class="sp-req-qty-panel">
                                <div class="btn-qty">
                                    <ion-icon name="md-remove" class="btn-qty-remove"
                                              [ngClass]="{'stay': qtyTag[i] > 0}"
                                              (click)="removeClicked(i);$event.stopPropagation();"></ion-icon>
                                </div>
                                <span class="sp-req-qty">{{qtyTag[i]}}</span>
                                <div class="btn-qty">
                                    <ion-icon name="md-add" class="btn-qty-add stay"
                                              (click)="addClicked(i);$event.stopPropagation();"></ion-icon>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <ion-grid *ngIf="showImage">

                    <ion-row>

                        <ion-col col-12 class="item-col listing-item luxe-with-image"
                                 [ngClass]="{'out-of-stock': isSoldout(item), 'big-item': addItems.length <= 2}"
                                 *ngFor="let item of addItems; let i = index">

                            <div (click)="addClicked(i)">

                                <div class="listing-item-img">

                                    <div class="img-box"
                                         [ngStyle]="{'background-image': 'url(' + getImage(item, 'default', 300) + ')'}">
                                        <span class="image-overlay"></span>
                                        <div class="ofs-lab">{{'pages.main-listing.sold-out' | translate}}</div>
                                    </div>

                                    <ng-container>
                                        <div class="item-qt-counter" *ngIf="qtyTag[i] > 0">
                                            <span class="item-qt"
                                                  [ngClass]="{'bounceIn animated animated-s-counter': animateQtyMap['#'+item.id] == true }">
                                                <p>{{qtyTag[i]}}</p>
                                            </span>
                                        </div>
                                    </ng-container>

                                </div>


                                <div class="listing-item-counter with-img"
                                     [ngClass]="{'minus': qtyTag[i] == 0, 'add': qtyTag[i] > 0}">
                                    <div *ngIf="!isParent(item)" class="card-title">{{item.name}}</div>
                                    <div *ngIf="isParent(item)"
                                         class="card-title">{{item.title ? item.title : item.name}}</div>


                                    <div class="item-grid-price">
                                        <button ion-button clear class="icon-item-icon icon-item-remove disable-hover"
                                                (click)="removeClicked(i);$event.stopPropagation();">
                                            <ion-icon name="remove"></ion-icon>
                                        </button>
                                        <span>{{store.sign}}{{item.price}}</span>
                                        <ion-icon class="price-arrow"
                                                  name="{{!isParent(item)? 'add' : 'ios-arrow-forward'}}"></ion-icon>
                                    </div>

                                </div>


                            </div>


                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>

        </div>


        <div class="popup-btn-counter">
            <ion-button size="default" class="btn-pop-up disable-hover btn-cancel"
                        (click)="clearClicked()">{{'buttons.cancel' | translate}}</ion-button>
            <ion-button size="default" class="btn-pop-up disable-hover main-btn btn-confirm"
                        [class.ifSelect]="round(newTotal)<=0 && !this.isGift"
                        (click)="okClicked()">{{'buttons.confirm' | translate}} (+{{store.sign}}{{round(newTotal)}})
            </ion-button>
        </div>


    </div>

</ion-content>

