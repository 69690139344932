import { Storage } from '@ionic/storage';
import { CookieService } from 'ngx-cookie-service';
import { Config } from '../../config';
import { UUID } from 'angular2-uuid';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "ngx-cookie-service/cookie-service/cookie.service";
var ConfigService = /** @class */ (function () {
    function ConfigService(storage, cookieService) {
        this.storage = storage;
        this.cookieService = cookieService;
        this.translations = {};
        this.isCourt = false;
        this.loaded = {};
        // super();
        this.config = new Config();
    }
    ConfigService.prototype.setRegion = function (name) {
        this.configRegion = name;
        this.setCookie('config.region', name);
    };
    ConfigService.prototype.getMember = function () {
        return this.member;
    };
    ConfigService.prototype.restoreRegion = function () {
        this.configRegion = this.getCookie('config.region');
    };
    ConfigService.prototype.getRegion = function () {
        // this.configService.config.default.name;
        return this.configRegion;
    };
    ConfigService.prototype.get = function (name) {
        var map = this.getConfig();
        return map[name];
    };
    ConfigService.prototype.getConfig = function () {
        var map = null;
        if (this.configRegion) {
            map = this.config[this.configRegion];
        }
        if (!map) {
            map = this.config.default;
        }
        return map;
    };
    ConfigService.prototype.getApi = function () {
        if (this.host) {
            return this.host;
        }
        return this.get('api');
    };
    ConfigService.prototype.getLocale = function () {
        var locale = this.getCookie('locale');
        if (!locale) {
            locale = this.get('locale');
        }
        return locale;
    };
    ConfigService.prototype.setLocale = function (locale) {
        this.setCookie('locale', locale);
    };
    ConfigService.prototype.getCountry = function () {
        return this.get('country');
    };
    ConfigService.prototype.getGroupId = function () {
        return this.get('groupId');
    };
    ConfigService.prototype.getBrandId = function () {
        return this.brandId;
    };
    ConfigService.prototype.setBrandId = function (brandId) {
        this.brandId = brandId;
    };
    ConfigService.prototype.putPreference = function (key, value) {
        var _this = this;
        return this.storage.ready().then(function () {
            console.log('preference put', _this.storage.driver + " " + key);
            _this.storage.set(key, value);
        });
    };
    ConfigService.prototype.setUserToken = function () {
        if (this.getUserToken()) {
            console.log('user token already set');
            return;
        }
        var uuid = UUID.UUID().toString();
        // Cookie.set("_ut", uuid, 3650, '/', this.getRootDomain());
        this.cookieService.set('_ut', uuid, 3650, '/', this.getRootDomain());
    };
    ConfigService.prototype.getUserToken = function () {
        // return Cookie.get("_ut");
        return this.getCookie('_ut');
    };
    ConfigService.prototype.isDev = function () {
        // return "dev" === this.config.default['name'];
        return 'dev' === this.get('name');
    };
    ConfigService.prototype.isLocalhost = function () {
        return window.location.hostname === 'localhost';
    };
    ConfigService.prototype.isPrd = function () {
        /*
        if (this.config.default["production"]) {
            return true;
        }

        return false;
        */
        return this.get('production');
    };
    ConfigService.prototype.startSession = function (member) {
        var now = new Date().getTime();
        var timeout = member.sessionTimeout;
        console.log('timeout in', timeout);
        if (member.admin && !timeout) {
            timeout = 60 * 24;
        }
        if (timeout && timeout > 0) {
            var expireMs = now + 60000 * timeout;
            this.setLocal('session.expire', expireMs);
            console.log('expire when', new Date(expireMs));
        }
    };
    ConfigService.prototype.isSessionExpired = function () {
        var result = false;
        var expire = this.getLocal('session.expire');
        if (expire) {
            var now = new Date().getTime();
            result = expire < now;
            console.log('diff', expire - now);
        }
        console.log('session expired?', result);
        return result;
    };
    ConfigService.prototype.refreshSession = function (member) {
        if (!member) {
            return;
        }
        this.startSession(member);
    };
    ConfigService.prototype.setCookie = function (name, value) {
        // Cookie.set(name, value);
        // this.cookieService.put(name, value);
        // localStorage.setItem(name,value);
        this.cookieService.set(name, value, 0, '/');
    };
    ConfigService.prototype.deleteCookie = function (name) {
        // Cookie.set(name, value);
        // this.cookieService.put(name, value);
        // localStorage.setItem(name,value);
        this.cookieService.delete(name, '/');
    };
    ConfigService.prototype.getCookie = function (name) {
        // return Cookie.get(name);
        // return this.cookieService.get(name);
        return this.cookieService.get(name);
    };
    ConfigService.prototype.getPreference = function (key) {
        var _this = this;
        return this.storage.ready().then(function () {
            console.log('preference get', _this.storage.driver + " " + key);
            return _this.storage.get(key);
        });
    };
    ConfigService.prototype.setLocal = function (key, object) {
        localStorage.setItem(key, JSON.stringify(object));
    };
    ConfigService.prototype.getLocal = function (key) {
        var str = localStorage.getItem(key);
        if (!str || str === 'undefined') {
            return null;
        }
        return JSON.parse(str);
    };
    ConfigService.prototype.loadScript = function (url, callback, param) {
        var _this = this;
        var scriptId = param ? param['id'] : null;
        if (!scriptId && this.loaded[url] && url.indexOf('session.js') === -1) {
            console.log('script already loaded!!!', url);
            callback();
            return;
        }
        else if (scriptId) {
            var element = document.getElementById(scriptId);
            if (element) {
                element.remove();
            }
        }
        // Adding the script tag to the head as suggested before
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        if (param) {
            for (var key in param) {
                script.setAttribute(key, param[key]);
            }
        }
        script.onload = function () {
            _this.loaded[url] = true;
            if (callback) {
                // script.onreadystatechange = callback;
                callback();
            }
        };
        // Fire the loading
        if (url.indexOf('session.js') !== -1 && this.sessionScript) {
            head.removeChild(this.sessionScript);
            console.log('reload session.js');
        }
        var temp = head.appendChild(script);
        if (url.indexOf('session.js') !== -1) {
            this.sessionScript = temp;
        }
    };
    ConfigService.prototype.loadScriptJS = function (path, callback, param) {
        var _this = this;
        if (this.loaded[path]) {
            console.log('script already loaded!!!', path);
            callback();
            return;
        }
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = path;
        if (param) {
            for (var key in param) {
                script.setAttribute(key, param[key]);
            }
        }
        script.onload = function () {
            _this.loaded[path] = true;
            if (callback) {
                // script.onreadystatechange = callback;
                callback();
            }
        };
        head.appendChild(script);
    };
    ConfigService.prototype.getVersionCode = function () {
        return this.config['version'];
    };
    ConfigService.prototype.getCustomizationId = function () {
        return this.config['customizationId'];
    };
    // returns the current time or debug time
    ConfigService.prototype.getTime = function () {
        if (this.config.default.name === 'pos') {
            return new Date().getTime();
        }
        if (this.debugTime) {
            return this.debugTime;
        }
        return new Date().getTime();
    };
    ConfigService.prototype.setDebugTime = function (time) {
        this.debugTime = time;
    };
    ConfigService.prototype.setHost = function (host) {
        this.host = host;
    };
    ConfigService.prototype.isChina = function () {
        return this.get('country') === 'cn';
    };
    ConfigService.prototype.getSessionId = function () {
        if (this.sessionId) {
            return this.sessionId;
        }
        return this.getCookie('sid');
    };
    ConfigService.prototype.setSessionId = function (sessionId) {
        this.sessionId = sessionId;
        this.setCookie('sid', this.sessionId);
    };
    ConfigService.prototype.clearSessionId = function () {
        this.sessionId = null;
        this.deleteCookie('sid');
    };
    ConfigService.prototype.getRootDomain = function () {
        var temp = location.hostname.split('.').reverse();
        var rootDomain = temp[0];
        if (temp[1]) {
            rootDomain = temp[1] + '.' + rootDomain;
        }
        console.log('root Domain', rootDomain);
        return rootDomain;
    };
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.inject(i1.Storage), i0.inject(i2.CookieService)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
