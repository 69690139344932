import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { RouteTable } from '../route-table';
import { OrderService } from './order.service';
import { MenuService } from './menu.service';
import { OrderManager } from 'aigens-ng-core';
import { TranslateService } from '@ngx-translate/core';

/**
 *  Use this singleton service to maintain state, pass parameters between navigation
 * */
@Injectable({
    providedIn: 'root'
})
export class NavigationService extends BaseService {
    // TODO : keep a copy of cache in localstorage to handle refresh?
    cache: any = {};
    navigating = false;
    lastParams: any;
    poping = false;
    // private pageStack: string[] = [];
    deactivateResult: Subject<boolean> = new Subject<boolean>();
    backByLocation = false;
    isAlertingDeactive = false;
    private rootPageUrl = '';

    constructor(public configs: ConfigService, public route: ActivatedRoute, public location: Location,
        public router: Router, public navController: NavController, public orderService: OrderService,
        public menuService: MenuService, public orderManager: OrderManager, public t: TranslateService) {
        super();
    }

    popPage() {
        this.poping = true;
        console.log('poping');
        // this.navController.pop().then(() => {
        //     console.log('pop done', this.poping);
        //     // this.poping = false;
        // });
        this.navController.setDirection('back', true, 'back');

        this.location.back();
    }

    popToParent(route: ActivatedRoute) {
        this.poping = true;
        this.navController.pop().then(() => {
            console.log('pop done', this.poping);
            // this.poping = false;
        });
        // this.navController.setDirection('back');
        // this.router.navigate(['../'], {relativeTo: route, replaceUrl: true}).then(() => {
        // });
    }

    getNavParams(key: string) {
        const navigation = this.router.getCurrentNavigation();
        if (!navigation || !navigation.extras || !navigation.extras.state || !navigation.extras.state[key]) {
            // check route params;
            /**
             *  Please make sure your component is never reused, ie. change from one item to another without destroy the original one.
             *  Otherwise use the code below:
             *  this.route.paramMap.pipe(
             *  switchMap((params: ParamMap) =>
             *      params.get('id')
             *  );
             */

            if (this.route.snapshot.paramMap.has(key)) {
                return this.route.snapshot.paramMap.get(key);
            } else {
                // check search params?
                let params = (new URL(window.location.href)).searchParams;
                if (params.get(key))
                    return params.get(key);
                else
                    return;
            }
        }
        return navigation.extras.state[key];
    }

    popToRoot() {
        this.poping = true;
        this.navController.setDirection('root', true, 'back');
        this.navController.navigateRoot(this.getRootPageUrl()).then(() => {
            // this.poping = false;
        });
    }

    getStore(storeId) {
        if (storeId && !this.orderService.store && this.orderManager.mode) {
            this.orderService.storeId = storeId;
            this.menuService.getStore(storeId, true, this.orderManager.mode, this.t.currentLang).subscribe(store => {
                if (store) {
                    this.orderService.setStore_BehaviorSubject(store);
                }
            }, err => {
                console.log(err);
            });
        }
    }

    configRootUrl() {
        console.log('config root url in navigationService');
        // TODO: config the root page
        /**
         *  check params to generate root page url
         *  /store/{id}/spot/{tableNo}
         *  /store/{id}/mode/{mode}
         *  /court-store-list/{id}/mode/{mode}
         *  /court-store-list//{id}/spot/{tableNo} ?
         *  please add more cases here
         * */
        const temp = window.location.pathname;
        let rootUrl = '';
        const storeIndex = temp.indexOf('store/');
        const courtIndex = temp.indexOf('court-store-list/');
        let modeEnd = 0;

        // getStore
        let storeId, mode = '', spot;

        if (storeIndex !== -1) {
            rootUrl += 'store/';
            modeEnd = temp.indexOf('/', storeIndex + 6);
            if (modeEnd === -1) {
                modeEnd = temp.length;
            }
            rootUrl += temp.substring(storeIndex + 6, modeEnd);
            // getStore
            storeId = temp.substring(storeIndex + 6, modeEnd);
        } else if (courtIndex !== -1) {
            rootUrl += 'court-store-list/';
            modeEnd = temp.indexOf('/', courtIndex + 17);
            if (modeEnd === -1) {
                modeEnd = temp.length;
            }
            rootUrl += temp.substring(courtIndex + 17, modeEnd);
        }
        const spotIndex = temp.indexOf('spot/');
        if (spotIndex !== -1) {
            rootUrl += '/spot/';
            modeEnd = temp.indexOf('/', spotIndex + 5);
            if (modeEnd !== -1) {
                rootUrl += temp.substring(spotIndex + 5, modeEnd);
                spot = temp.substring(spotIndex + 5, modeEnd);
            } else {
                rootUrl += temp.substring(spotIndex + 5);
                spot = temp.substring(spotIndex + 5);
            }
        }
        const courtModeIndex = temp.indexOf('court/'); // court  Mode
        if (courtModeIndex !== -1) {
            rootUrl += 'court/';
            modeEnd = temp.indexOf('/', courtModeIndex + 6);
            rootUrl += temp.substring(courtModeIndex + 6, modeEnd !== -1 ? modeEnd : temp.length + 1); // court/123231   后面没 /
        }
        const historyIndex = temp.indexOf('history/'); // court  Mode
        if (historyIndex !== -1) {
            // direct history page
            rootUrl += temp;
        }
        const keyIndex = temp.indexOf('key/');
        if (keyIndex !== -1) {
            rootUrl += '/key/';
            let keyEnd = temp.indexOf('/', keyIndex + 4);
            if (keyEnd !== -1) {
                rootUrl += temp.substring(keyIndex + 4, keyEnd);
            } else {
                rootUrl += temp.substring(keyIndex + 4);
            }
        }
        const modeIndex = temp.indexOf('mode/');
        if (modeIndex !== -1) {
            rootUrl += '/mode/';
            modeEnd = temp.indexOf('/', modeIndex + 5);
            // let mode = '';
            if (modeEnd !== -1) {
                mode = temp.substring(modeIndex + 5, modeEnd);
            } else {
                mode = temp.substring(modeIndex + 5);
            }
            if (mode === 'takeaway' || mode === 'pickup' || mode === 'dinein' || mode === 'delivery' || mode === 'preorder' || mode === 'prekiosk') {
                rootUrl += mode;
            } else {
                console.warn('invalid mode ,do not set root url', mode);
                return;
            }
        }
        this.setRootPageUrl(rootUrl);
        // url init this.orderManager.mode start
        if (courtModeIndex === -1) {
            if (spot) {
                if (mode) {
                    this.orderManager.mode = mode;
                } else {
                    this.orderManager.mode = 'byod';
                }
            } else if (mode === 'preorder' || !mode) {
                this.orderManager.mode = 'preorder';
                this.orderService.preOrder = true;
            } else {
                this.orderManager.mode = mode;
            }
            this.orderManager.initMode = this.orderManager.mode;
            // url init this.orderManager.mode end

            if (storeId && this.orderManager.mode) {
                console.log('url get store(storeId, mode)', storeId, mode);
                this.getStore(storeId);
            }
        }



        console.log('set root Url in navigation service', rootUrl);
        if (!(<any>document.getElementById('baseHref')).href || (<any>document.getElementById('baseHref')).href === '/') {
            (<any>document.getElementById('baseHref')).href = rootUrl;
        }
    }

    setRootPageUrl(url: string) {
        if (!url) {
            console.log('wrong input root url, reject', url);
            return;
        }
        this.rootPageUrl = url;
        this.configs.setLocal('rootPage', this.rootPageUrl);
        console.log('set root page ', url);
    }

    getRootPageUrl(ignoreCache = false): string {
        if (ignoreCache && !this.rootPageUrl) {
            this.rootPageUrl = this.configs.getLocal('rootPage');
        }
        return this.rootPageUrl;
    }

    setNavigationParams(params: any) {
        this.cache = params;
    }

    getNavigationParams() {
        return this.cache;
    }

    push(page, params: any = {}, extras: NavigationExtras = {}): Promise<boolean> {
        const targetUrl = page.getPageUrl();
        return this.pushByUrl(targetUrl, params, extras);
    }

    pushByName(pageName, params: any = {}, extras: NavigationExtras = {}): Promise<boolean> {
        const targetUrl = RouteTable[pageName];
        if (!targetUrl) {
            console.error('cannot find url for page ', pageName);
        } else {
            console.log(`page ${pageName} at ${targetUrl}`);
        }
        return this.pushByUrl(targetUrl, params, extras);
    }

    pushByUrl(pageUrl, params?: any, extras?: NavigationExtras): Promise<boolean> {
        console.log('push by url', pageUrl);
        console.log('params', params);
        console.log('extras', extras);
        let temp = {};
        if (!this.getRootPageUrl() || this.getRootPageUrl() === '') {
            this.configRootUrl();
        }
        if (extras) {
            extras['state'] = params;
            // will this be true if extras.relativeTo = this.route.root?
            if (extras.relativeTo === this.route) {
                pageUrl = window.location.pathname + '/' + pageUrl;
            } else if (!extras.relativeTo) {
                pageUrl = this.getRootPageUrl() + '/' + pageUrl;
            }
            temp = extras;
        } else {
            pageUrl = this.getRootPageUrl() + '/' + pageUrl;
            temp['state'] = params;
        }
        // temp['skipLocationChange'] = false;
        // temp['replaceUrl'] = false;
        this.setNavigationParams(params);
        console.log('push to absolute url', pageUrl);
        this.backByLocation = extras && (extras.skipLocationChange);
        if (this.backByLocation) {
            window.history.pushState({}, 'Order.Place', window.location.href);
        }
        this.lastParams = params;

        temp['queryParamsHandling'] = 'merge';
        return this.navController.navigateForward([pageUrl], temp);
    }

}
