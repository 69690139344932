import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { MobileBasePage } from '../../core/base/mobile-base-page';

import { Address, Location } from 'aigens-ng-core';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialog extends MobileBasePage implements OnInit {

  // addressArr = [];
  addressArr: Address[] = [];
  currentAddress; // the mark of the currentAddress:Address
  pickupAddress;
  pickupAddressGroups: any = [];
  orderItem;
  charge;

  pickupArr: any = [];

  isRemove = false;
  willRemoveAddressArr = []; // save the arr of address:Address(will removing)

  isRoute: boolean;
  loginCb: any; // it is the root-page nextClicked()

  addAddressCb: any; // it is addressDialog when popup but open new page(add address page) and add address success callback;

  storeLocation: Location;
  constructor(public injector: Injector, private cd: ChangeDetectorRef) {
    super(injector);
    this.storeLocation = this.orderService.store.location;
    this.isRoute = this.getNavParams('isRoute') || false;
    this.loginCb = this.getNavParams('loginCb');
    this.orderItem = this.getNavParams('orderItem');
    this.charge = this.getNavParams('charge');

    this.addAddressCb = this.getNavParams('addAddressCb');
    this.getAddresses();
  }

  ngOnInit() {
    this.addressArr = this.addressManagerService.addressArr;
    console.log('AddressDialog -> ngOnInit -> this.addressArr', this.addressArr);
    this.currentAddress = this.addressManagerService.currentAddress;

    // todo 暂时pickup地址为store location
    this.addressManagerService.initPickupArr();
    this.pickupAddressGroups = this.addressManagerService.pickupArr;

    // this.addressArr = [{
    //   name: 'heheh',
    // }, {
    //   name: 'ggg'
    // }, {
    //   name: 'fff'
    // }];
  }


  selectAddressChange(ev) {
    // console.log('selectAddressChange ev', ev, this.currentAddress);
    if (!this.currentAddress) return;
    this.addressManagerService.setPickup(false);
    this.pickupAddress = null;
  }

  removeAddressArrChange(i) {
    console.log('removeAddressArrChange', i);
    console.log('removeAddressChange willRemoveAddressArr:', this.willRemoveAddressArr);
    let val = this.willRemoveAddressArr.indexOf(this.addressArr[i]);
    if (val === -1) {
      // select new address (will removing)
      this.willRemoveAddressArr.push(this.addressArr[i]);
    } else {
      // cancel the selecting address(will removing)
      this.willRemoveAddressArr = this.willRemoveAddressArr.filter(el => el.id !== this.addressArr[i]['id']);
    }

    console.log('removeAddressChange willRemoveAddressArr:', this.willRemoveAddressArr);
  }
  getAddresses() {
    this.loading(true);
    this.addressManagerService.getAddresses(this.orderService.store).subscribe(addres => {
      this.loading(false);
      console.log(addres);

      this.addressArr = addres;

    }, err => {
      this.loading(false);
      return this.showAlert(err.status, err['error']);
    });
  }
  addNewAdress() {
    console.log('addNewAdress cliking', this.addAddressCb);
    let parmas = {};
    if (this.addAddressCb)
      parmas['addAddressCb'] = this.addAddressCb;
      parmas['getAddress'] = () => this.getAddresses();
    this.pushByName('AddAddressPage', parmas, {}).then(() => {
    });
  }
  calculate(address){
    this.loading(true);
    this.addressManagerService.calculateAddress(address, this.orderService.store, this.orderItem).subscribe(result => {
      this.loading(false);
      if (result && result.couriers && result.couriers.length < 1){
        this.showAlert('fail', 'no couriers provide');
        return;
      }

    }, err => {
      this.loading(false);
      return this.showAlert(err.status, err['error']);
    });
  }
  closeClicked() {
    if (this.isRoute) {
      // 路由导航 from the delivery-login registe successly
      if (this.isRemove) {
        this.isRemove = false;
      } else
        this.popToRoot().then(() => {
          if (this.loginCb)
            this.loginCb();
        });
    } else {
      // modal
      if (!this.isRemove)
        this.modalController.dismiss({});
      else {
        this.isRemove = false;
      }
    }

  }

  okClicked() {


    if (this.addressManagerService.isPickup) {
      console.log('选定 pick up', this.pickupAddress);
      // update the addressManagerService pickupAddress
      this.addressManagerService.setPickupAddress(this.pickupAddress);
      if (this.isRoute){
        this.popToRoot();
      }else
        this.modalController.dismiss({});

    } else {
      console.log('选定 delivery address', this.currentAddress);
      let address = this.addressManagerService.findAddress(this.currentAddress);
      console.log('findAddress by index success ,address', address);

      // save the ordering-time address
      if (address){
        this.calculate(address);
        this.addressManagerService.saveCurrentAddress(address);
      }
      else {
        console.log('not find the current addressId of Address');
        return;
      }

      if (this.isRoute) {
        // todo
        // 1. if currentAddress is valid push to cat-list-page with login
        this.popToRoot().then(() => {
          if (this.loginCb)
            this.loginCb();
        });
        // 2. if invalid push to cat-list-page with not login
      } else
        this.modalController.dismiss({});
    }

  }

  removeClicked() {
    console.log('removeClicked willRemoveAddressArr', this.willRemoveAddressArr);
    if (this.willRemoveAddressArr.length < 1) {
      console.log('it is not select item which will be removing');
    } else {
      // todo call api to remove the address
      this.willRemoveAddressArr.forEach((el) => {

        // find the removing addressId according to the indexArr
        let address: Address = this.addressManagerService.findAddress(el);
        let addressId = address['id'];
        // if delete-address is currentAddress , so need to update currentAddress;
        if (this.currentAddress && addressId === this.currentAddress['id']) {
          this.addressManagerService.saveCurrentAddress(null);
          this.addressManagerService.couriers = [];
        }
        this.postRemoveAddress(addressId);
      });

    }
  }
  setCurrentAddressNull(){
    console.log('setCurrentAddressNull');
    this.addressManagerService.setPickup(true);
    this.currentAddress = null;
  }
  setPickupAddressNull(){
    this.addressManagerService.setPickup(false);
    this.pickupAddress = null;
  }
  postRemoveAddress(addressId) {
    if (addressId)
      this.addressManagerService.deleteAddress(addressId).subscribe((result) => {
        console.log('deleteAddress() result:', result);
        this.addressArr.map((ad, i) => ad['id'] === addressId && this.addressArr.splice(i, 1) );
        this.addressManagerService.addressArr = this.addressArr;
        this.isRemove = false;
      }, err => {
        this.loading(false);
        return this.showAlert(err.status, err['error']);
      });
  }

  removeHandle() {
    console.log('removeHandle clicking');
    this.isRemove = true;
    this.cd.detectChanges();

  }

  trackAddressById(index, address) {
    return address.id;
  }
}
