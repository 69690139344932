var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// import { ViewController } from 'ionic-angular';
import { Injector, OnInit } from '@angular/core';
import { Item } from 'aigens-ng-core';
import { MathUtils } from 'aigens-ng-core';
import { NavParams } from '@ionic/angular';
import { MobileBaseItemPage } from 'src/app/core/base/mobile-base-item-page';
import { getSetLayout } from "../../shared/utilities/layout-helper";
import { MultiStepComboPage } from "../../routes/item/multi-step-combo/multi-step-combo-page.component";
import { ComboPage } from "../../routes/item/combo-page/combo-page";
import { VariationScrollDialog } from "../variation-scroll/variation-scroll";
import { popDrownAnimation, popUpAnimation } from "../../shared/animations/leaveAnimation";
import { ModifierItemSelectPageModal } from "../../routes/item/modifier-item-select-modal/modifier-item-select-modal";
var UnlockPopupDialog = /** @class */ (function (_super) {
    __extends(UnlockPopupDialog, _super);
    function UnlockPopupDialog(injector, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.animateQtyMap = {};
        _this.newTotal = 0;
        _this.addItems = []; // all single items in recommendation list
        _this.qtyTag = [];
        _this.displayTitle = '';
        _this.soldoutMap = {};
        _this.modifiableMap = {};
        _this.requiredModifierMap = {};
        _this.isVariationOpened = false;
        _this.showImage = true;
        _this.isGift = false; // deal with free items
        _this.comboCache = new Map();
        _this.recommendList = _this.navParams.get('recommendList');
        _this.store = _this.navParams.get('store');
        _this.addItems = _this.navParams.get('items');
        _this.isGift = _this.navParams.get('isGift');
        _this.max = _this.navParams.get('max'); // for unlocks
        _this.order = _this.orderManager.order;
        _this.recommendations = _this.navParams.get('recommendations'); // for AI recommendations
        if (_this.max) {
            _this.displayTitle = _this.t.instant('pages.item-select.chooseAtMost', { max: _this.max });
        }
        _this.init();
        return _this;
    }
    UnlockPopupDialog.prototype.init = function () {
        var _this = this;
        if (this.recommendations) {
            this.addItems = [];
            this.recommendations.forEach(function (rm) {
                _this.addItems.push(rm.item);
                _this.qtyTag.push(0);
                if (_this.showImage && (!rm.item.images || !rm.item.images['default']))
                    _this.showImage = false;
            });
        }
        else {
            for (var i = 0; i < this.addItems.length; i++) {
                this.qtyTag[i] = 0;
                if (!this.addItems[i].parentId || this.addItems[i].parentId == this.addItems[i].id) {
                    if (this.showImage && (!this.addItems[i].images || !this.addItems[i].images['default']))
                        this.showImage = false;
                }
            }
            this.category = this.recommendList;
            this.itemgroups = this.recommendList.groups;
        }
        console.log('input category', this.category);
    };
    UnlockPopupDialog.prototype.updateUI = function () {
    };
    UnlockPopupDialog.prototype.isModifiable = function (item) {
        if (!this.modifiableMap[item.id]) {
            this.modifiableMap[item.id] = Item.isModifiable(item);
        }
        return this.modifiableMap[item.id];
    };
    UnlockPopupDialog.prototype.checkStoreMaxItem = function () {
        var maxItems = this.orderManager.store.menu.maxItems;
        var infinity = maxItems === 0;
        return (maxItems <= this.orderManager.count && !infinity);
    };
    UnlockPopupDialog.prototype.isRequiredModifier = function (item) {
        if (this.isModifiable(item)) {
            if (!this.requiredModifierMap[item.id]) {
                this.requiredModifierMap[item.id] = item.mgroups.some(function (mgroup) { return mgroup.min > 0; });
            }
            return this.requiredModifierMap[item.id];
        }
        else {
            return false;
        }
    };
    UnlockPopupDialog.prototype.isSet = function (item) {
        return Item.isSet(this.category, item);
    };
    UnlockPopupDialog.prototype.toComboPage = function (item, index, parentIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var page, componentProps, cssClass, modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        page = getSetLayout(this.orderManager.store.menu.setLayout);
                        componentProps = {
                            store: this.store,
                            category: this.category,
                            groups: this.category.groups,
                            item: Item.cloneArray(Item, [item])[0],
                            handler: this.getNavParams('handler'),
                            onHold: true,
                            quantityLimit: this.max
                        };
                        cssClass = 'modal-largescreen combo-modal-backdrop-dismiss';
                        return [4 /*yield*/, this.navToAssignLayoutItems(page === 'MultiStepComboPage' ? MultiStepComboPage : ComboPage, page, componentProps, {
                                relativeTo: this.route,
                                skipLocationChange: false
                            }, cssClass)];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (data) {
                            console.log(data);
                            _this.onAddClicked(index, isNaN(parentIndex) ? index : parentIndex, data['data']['orderItem']);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    UnlockPopupDialog.prototype.toModifierPage = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    UnlockPopupDialog.prototype.isVariationParent = function (item, index) {
        return __awaiter(this, void 0, void 0, function () {
            var exist, items, input, modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        exist = this.isParent(item);
                        if (!exist) return [3 /*break*/, 2];
                        items = item.variations.slice(0) || [];
                        if (item.id === item.parentId) {
                            items.splice(0, 0, item);
                        }
                        else {
                            items.push(item);
                        }
                        input = {
                            items: items,
                            store: this.store,
                            category: this.category,
                            orderManager: this.orderManager
                        };
                        console.log('isVariationParent input', input);
                        return [4 /*yield*/, this.modalController.create({
                                component: VariationScrollDialog,
                                componentProps: input,
                                showBackdrop: true,
                                backdropDismiss: true,
                                cssClass: 'modal-largescreen',
                                animated: true,
                                enterAnimation: popUpAnimation,
                                leaveAnimation: popDrownAnimation
                            })];
                    case 1:
                        modal = _a.sent();
                        modal.onWillDismiss().then(function (data) { return __awaiter(_this, void 0, void 0, function () {
                            var selected, j, oi, params, m;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        selected = (data['data']);
                                        if (!selected) return [3 /*break*/, 4];
                                        console.log('select & add item,', selected);
                                        if (!(this.isSet(selected) || selected.pgroup)) return [3 /*break*/, 2];
                                        j = void 0;
                                        for (j = 0; j < this.addItems.length; j++) {
                                            if (this.addItems[j].id == selected.id)
                                                break;
                                        }
                                        return [4 /*yield*/, this.toComboPage(selected, index, j)];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 4];
                                    case 2:
                                        oi = this.addItem(selected);
                                        if (!(this.isRequiredModifier(selected) || (this.isModifiable(selected) && !this.store.menu.quickSingleItem))) return [3 /*break*/, 4];
                                        params = { store: this.store, orderitem: oi, edit: true, onHold: true };
                                        return [4 /*yield*/, this.modalController.create({
                                                component: ModifierItemSelectPageModal,
                                                componentProps: params,
                                                showBackdrop: false,
                                                enterAnimation: popUpAnimation,
                                                leaveAnimation: popDrownAnimation,
                                                cssClass: 'modal-largescreen variation-item-modifier-select'
                                            })];
                                    case 3:
                                        m = _a.sent();
                                        m.onDidDismiss().then(function (data) {
                                            console.log('ModifierItemSelectPageModal res', data);
                                            if (data['data'] === 'confirm') {
                                                // save item to temp list
                                            }
                                            else {
                                                // this.removeClicked(selected);
                                            }
                                        });
                                        m.present();
                                        return [3 /*break*/, 4];
                                    case 4:
                                        this.isVariationOpened = false;
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this.isVariationOpened = true;
                        modal.present();
                        _a.label = 2;
                    case 2: return [2 /*return*/, exist];
                }
            });
        });
    };
    UnlockPopupDialog.prototype.isParent = function (item) {
        return !!(item.variations && item.variations.length > 0);
    };
    // private isVariationParent(i: number) {
    //
    //     let item = this.addItems[i];
    //
    //     let exist: boolean = this.isParent(item);
    //
    //     if (exist) {
    //
    //         let items = item.variations.slice(0);
    //
    //         items.push(item);
    //
    //         // this.presentDialog2(VariationDialog, { items: items, store: this.store, takeout: this.order.takeout }, (selected) => {
    //
    //         //     if (selected) {
    //                 let j: number;
    //                 for (j = 0; j < this.addItems.length; j++) {
    //                     if (this.addItems[j].id == selected.id) break;
    //                 }
    //
    //         //         this.onAddClicked(i, j);
    //         //     }
    //         // });
    //     }
    //
    //     return exist;
    // }
    UnlockPopupDialog.prototype.isSoldout = function (item) {
        // let now = this.getNow();
        return !this.isParent(item) && (this.inventoryManager.isSoldout(item)); // || !this.menuManager.isAvailableCombo(this.store, this.category, item, this.order.takeout, now));
    };
    // i: parent, j:selected
    UnlockPopupDialog.prototype.onAddClicked = function (i, j, oi) {
        var _this = this;
        this.animateQtyMap['#' + this.addItems[i].id] = false;
        setTimeout(function () {
            _this.animateQtyMap['#' + _this.addItems[i].id] = true;
        }, 10);
        this.addItems[j].quantity++;
        this.qtyTag[i]++;
        this.newTotal += this.round(this.addItems[j].price);
        if (oi) {
            this.comboCache.set(j, oi);
        }
    };
    UnlockPopupDialog.prototype.checkTotalQty = function () {
        var qty = 0;
        this.addItems.forEach(function (item) {
            qty += item.quantity;
        });
        console.log('selected qty:', qty);
        return qty;
    };
    // addClicked(i: number) {
    //
    //     // this.playClick();
    //
    //     if (this.max && this.checkTotalQty() == this.max) {
    //         if (this.max !== 1) return;
    //
    //         // clear other selection if single select
    //         for (let index = 0; index < this.addItems.length; index++) {
    //             while (this.qtyTag[index] > 0) this.minusItem(index);
    //         }
    //     }
    //     let item = this.addItems[i];
    //
    //     if (this.checkTotalQty() == this.max) return;
    //
    //     // deal with group items
    //     if (this.isVariationParent(i)) return;
    //
    //     // soldout
    //     if (this.isSoldout(item)) return;
    //
    //     // single item
    //     this.onAddClicked(i, i);
    //
    //     console.log('add single item#' + i, this.addItems[i].name);
    // }
    UnlockPopupDialog.prototype.addClicked = function (i) {
        return __awaiter(this, void 0, void 0, function () {
            var index, item, t, cate_1, oi, _groups, groups, oi;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.max && this.checkTotalQty() >= this.max) {
                            if (this.max !== 1)
                                return [2 /*return*/];
                            // clear other selection if single select
                            for (index = 0; index < this.addItems.length; index++) {
                                while (this.qtyTag[index] > 0)
                                    this.minusItem(index);
                            }
                        }
                        console.log('add clicked', i);
                        if (this.checkStoreMaxItem()) {
                            this.showStoreMaxItemAlert();
                            return [2 /*return*/];
                        }
                        item = this.addItems[i];
                        return [4 /*yield*/, this.isVariationParent(item, i)];
                    case 1:
                        t = _a.sent();
                        console.log('variation parent', t);
                        if (t) {
                            return [2 /*return*/];
                        }
                        if (this.isSoldout(item)) {
                            return [2 /*return*/];
                        }
                        if (!this.isSet(item)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.toComboPage(item, i)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        // if cannot skip modifier page
                        if (this.isRequiredModifier(item) || (this.isModifiable(item) && !this.store.menu.quickSingleItem)) {
                            cate_1 = this.category;
                            oi = this.addItem(item);
                            _groups = cate_1.groups, groups = _groups && _groups.filter(function (group) {
                                return _this.menuManager.isAvailableGroup(_this.store, cate_1, group, _this.orderManager.isTakeout(), Date.now(), false);
                            });
                            this.onAddClicked(i, i);
                            // this.pushByName('ModifierItemSelectPage', {store: this.store, orderitem: oi}, {
                            //     relativeTo: this.route,
                            //     //  // replaceUrl: true,
                            //     skipLocationChange: false
                            // });
                        }
                        else {
                            oi = this.addItem(item);
                            {
                                // if (!this.categories) {
                                //
                                //     const handler = this.getNavParams('handler');
                                //     handler(oi);
                                //     this.orderManager.minusItem(item, true);
                                //     this.navigationService.popPage();
                                //
                                // }
                            }
                        }
                        _a.label = 4;
                    case 4:
                        if (this.orderService.isCourt) {
                            this.gaService.eventTrack('item-list-page', 'select item - ' + this.store.name, item.name);
                        }
                        // this.bounce();
                        console.log(this.orderManager.getQuantity(item));
                        return [2 /*return*/];
                }
            });
        });
    };
    UnlockPopupDialog.prototype.add = function (item, group, oi, isPreselect, callBack) {
        if (isPreselect === void 0) { isPreselect = false; }
        var groupIndex = item.groups.findIndex(function (g) {
            // TODO: is compared by id safe?
            return (g !== null && g !== undefined && g.id === group.id);
        }) + 1;
        var isOptional = group.min === 0;
        // if (this.getGroupMode(group) === 'single') {
        //     // special request only can change isSelected
        //     const max = group.max;
        //     let able = true;
        //     if (!isPreselect) {
        //         if (this.isGroupCompleted(group, groupIndex, 1)) {
        //             this.handlingModifier(oi, item, this.itemMap, () => {
        //             }, () => {
        //                 // this.minusClicked(item, group);
        //                 this.removeClicked(item, callBack);
        //             });
        //         } else if (isOptional) {
        //             const realItem = item;
        //             if (realItem.mgroups && realItem.mgroups.length > 0) {
        //                 this.handlingModifier(oi, item, this.itemMap, () => {
        //                     // this.showToast(this.t.instant('global.item-added'));
        //                 }, () => {
        //                     // this.minusClicked(item, group);
        //                     this.removeClicked(item);
        //                 }, true);
        //
        //             }
        //         }
        //
        //     }
        //
        // } else if (this.getGroupMode(group) === 'quantity') {
        //     // special request can change the number
        // }
    };
    UnlockPopupDialog.prototype.addItem = function (item) {
        // this.orderService.addSingleItem(this.category, this.groups[0], item);
        var oi = this.orderManager.addSingleItem(this.recommendList, this.recommendList.groups[0], item, false);
        this.orderManager.getQuantity(item);
        if (!(this.isRequiredModifier(item) || (this.isModifiable(item) && !this.settings.quickSingleItem))) {
            // this.showToast(this.t.instant('global.item-added'), 600, 'bottom', 'add-item-toast');
        }
        return oi;
    };
    UnlockPopupDialog.prototype.removeVariation = function (i) {
        var _this = this;
        var item = this.addItems[i];
        var items = item.variations.slice(0);
        items.push(item);
        this.qtyTag[i] = 0;
        items.forEach(function (i) {
            for (var j = 0; j < _this.addItems.length; j++) {
                if (_this.addItems[j].id == i.id) {
                    _this.newTotal -= _this.round(_this.addItems[j].price * _this.addItems[j].quantity);
                    _this.addItems[j].quantity = 0;
                }
            }
        });
    };
    UnlockPopupDialog.prototype.minusItem = function (i) {
        if (this.isParent(this.addItems[i])) {
            this.removeVariation(i);
        }
        else {
            this.addItems[i].quantity--;
            this.qtyTag[i]--;
            this.newTotal -= this.round(this.addItems[i].price);
        }
    };
    UnlockPopupDialog.prototype.removeClicked = function (i) {
        if (this.qtyTag[i] <= 0)
            return;
        // this.playClick();
        console.log('remove clicked');
        this.minusItem(i);
    };
    UnlockPopupDialog.prototype.clearClicked = function () {
        // this.playClick();
        this.updateUI();
        this.modalController.dismiss('cancel');
    };
    UnlockPopupDialog.prototype.okClicked = function () {
        // this.playClick();
        if (this.round(this.newTotal) <= 0 && !this.isGift)
            return;
        /*
        this.presentDialog(ConfirmDialog, this.t.instant("pages.recommend-list.confirmTitle"), null, null, data => {

            if (data == "ok") {

                console.log('Buy clicked');
                let order: Order = this.orderManager.order;

                this.addItems.forEach(item =>{
                    for (let i = 0; i < item.quantity; i++) {
                        let now: number = this.getNow();
                        let group: ItemGroup = this.itemgroups[0];
                        let oi = this.orderManager.addSingleItem(this.category, group, item);
                    }
                });

                this.onOrderChanged(this.orderManager.order);

                this.updateUI();

                this.viewCtrl.dismiss("ok");
            } else {
                console.log('Cancel clicked');
                this.viewCtrl.dismiss(null);
            }
        });
        */
        console.log('Buy clicked');
        var order = this.orderManager.order;
        for (var index = 0; index < this.addItems.length; index++) {
            var item = this.addItems[index];
            for (var i = 0; i < item.quantity; i++) {
                var group = void 0;
                if (this.recommendations) {
                    group = this.recommendations[index].itemgroup;
                    this.category = this.recommendations[index].category;
                }
                else {
                    group = this.itemgroups[0];
                }
                var oi = void 0;
                if (this.comboCache.has(index)) {
                    oi = this.comboCache.get(index);
                    this.orderManager.addOrderItem(oi);
                }
                else {
                    oi = this.orderManager.addSingleItem(this.category, group, item);
                }
                if (this.isGift) {
                    if (!order.gifts)
                        order.gifts = [];
                    order.gifts.push(oi);
                }
            }
        }
        this.updateUI();
        this.modalController.dismiss('ok');
    };
    UnlockPopupDialog.prototype.round = function (value) {
        return MathUtils.round(value, 2);
    };
    UnlockPopupDialog.prototype.getItemName = function (item) {
        var name = item.name;
        if (this.isParent(item)) {
            name = item.title;
        }
        if (!name || name.length == 0) {
            name = item.name;
        }
        return name;
    };
    return UnlockPopupDialog;
}(MobileBaseItemPage));
export { UnlockPopupDialog };
