import {MobileBasePage} from '../../core/base/mobile-base-page';
import {Component, Injector, OnInit} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {Storage} from '@ionic/storage';

/*
  Generated class for the TermsConditions page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
    selector: 'page-terms-conditions',
    templateUrl: 'terms-conditions.html',
    styleUrls: ['terms-conditions.scss']
})
export class TermsConditionsPage extends MobileBasePage implements OnInit {
    isOffline = false;
    contentUrl: string;
    isAgree = false;
    marketing = true;
    needToAgreeMarketing = false;
    fbHandler: any;
    input = {};
    isFAQ = false;

    // constructor(public viewCtrl: ViewController, injector: Injector, params: NavParams,public startupService: StartupService
    constructor(injector: Injector, params: NavParams, public storage: Storage) {
        super(injector);

        const locale: string = this.t.currentLang;
        /*if (locale == "zh") {
            this.contentUrl = "https://storage.googleapis.com/aigensstoretest.appspot.com/70000/ssptnc.html";
        } else {
            this.contentUrl = "https://storage.googleapis.com/aigensstoretest.appspot.com/70000/ssptnc.html";
        }*/
        this.contentUrl = params.get('url');
        this.isFAQ = params.get('isFAQ');
        this.needToAgreeMarketing = params.get('needToAgreeMarketing');
        this.fbHandler = params.get('fbHandler');
        this.input['name'] = params.get('brandName');
    }

    ngOnInit() {

    }

    ionViewDidEnter() {
        this.loadContentApi();
        // this.getLangWithData();
    }


    getLangWithData() {
        this.loading(true);
        this.storage.ready().then(() => {

            this.storage.get('languageMode').then((lange) => {

                if (lange) {
                    this.loadContentApi(lange);
                } else {
                    this.loadContentApi();
                }

            }).catch(err => {
                this.loading(false);
            });


        }).catch(err => {
            this.loading(false);
        });
    }

    loadContentApi(lange?) {

        if (!this.isOffline) {
            const iframe = document.getElementById('iframe');
            iframe.setAttribute('src', this.contentUrl);
        }


    }

    clickMarketing() {
        this.marketing = !this.marketing;
    }


    dismiss(agree?: boolean) {
        console.log('dismiss');
        if (agree) {
            if (this.fbHandler) {
                this.fbHandler({agree: agree, marketing: this.marketing});

            }
            this.modalController.dismiss({agree: agree, marketing: this.marketing});

        } else {
            this.modalController.dismiss({fail: 'failed'});

        }
        // this.push(BrandStoreListPage, {brand:5637145314197504});
    }


    reload() {
        this.loadContentApi();
        // this.getLangWithData();
    }


}
