var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import * as firebase from 'firebase';
import 'firebase/database';
import 'rxjs/add/operator/map';
import { AQuery } from '../base/aquery';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
var MonitoringService = /** @class */ (function (_super) {
    __extends(MonitoringService, _super);
    function MonitoringService(http, configs) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.refs = [];
        _this.refsByKey = {};
        _this.enable = false;
        if (_this.configs.isChina()) {
            return _this;
        }
        var config = _this.configs.config.getFirebaseConfig();
        if (!_this.configs.firebase) {
            _this.configs.firebase = firebase.initializeApp(config);
            firebase.auth().signInAnonymously();
        }
        _this.database = firebase.database();
        _this.firestore = firebase.firestore();
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    Object.defineProperty(MonitoringService.prototype, "numberOfRefs", {
        get: function () {
            return Object.keys(this.refsByKey).length;
        },
        enumerable: true,
        configurable: true
    });
    MonitoringService.prototype.getStoreStatus = function (storeId) {
        var host = this.configs.get('statusHost');
        if (!host) {
            return null;
        }
        var url = host + '/api/v1/store/status.json?storeId=' + storeId;
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MonitoringService.prototype.listenOrder = function (nodeName, listCb, updateCb, removeCb) {
        console.log('checking nodeName', nodeName);
        if (nodeName === undefined) {
            console.warn('invalid node name, return');
            return;
        }
        var ref = this.database.ref(nodeName);
        console.log('checking ref', ref);
        this.refsByKey[nodeName] = ref;
        // console.log("checking this.refsByKey[attValue]", this.refsByKey[attValue]);
        ref.once('value', function (snapshot) {
            var data = snapshot.val();
            console.log('once update', data);
            listCb(data);
        }, function (error) {
            console.error(error);
            listCb(null);
        });
        ref.on('value', function (childSnapshot, prevChildKey) {
            var data = childSnapshot.val();
            console.log('child update', data);
            // callback(data);
            updateCb(data);
        }, function (error) {
            console.error(error);
        });
        if (removeCb) {
            ref.on('value', function (childSnapshot, prevChildKey) {
                var data = childSnapshot.val();
                console.log('child remove', data);
                // callback(data);
                removeCb(data);
            });
        }
    };
    MonitoringService.prototype.listenOrderStatus2 = function (nodeName, listCb, updateCb, removeCb) {
        this.listenOrder(nodeName, listCb, updateCb, removeCb);
    };
    MonitoringService.prototype.listenStoreOrder = function (nodeName, listCb, updateCb, removeCb) {
        this.listenOrders(nodeName, listCb, updateCb, removeCb);
    };
    MonitoringService.prototype.listenBatchStoreStatus = function (storeId, listCb, updateCb, removeCb) {
        var _this = this;
        var ref = this.database.ref('/status');
        console.log('ref', ref);
        ref.orderByChild('storeId').equalTo(storeId).once('value', function (snapshot) {
            var data = snapshot.val();
            console.log('once update', data);
            var orderOk;
            if (_this.configs.getGroupId() === '50000') {
                orderOk = _this.canOrder(data, 5);
            }
            else {
                orderOk = _this.canOrder(data, 15);
            }
            var mapping = {};
            mapping['order'] = orderOk;
            mapping['storeId'] = storeId;
            console.log('orderOk', mapping);
            listCb(mapping);
        }, function (error) {
            console.error(error);
        });
        // ref.once('value', (snapshot) => {
        //     var data = snapshot.val();
        //     console.log("once update", data);
        //     listCb(data);
        // }, error => {
        //     console.error(error);
        //     listCb(null);
        // });
        // ref.on('value', (childSnapshot, prevChildKey) => {
        //     var data = childSnapshot.val();
        //     console.log("child update", data);
        //     //callback(data);
        //     updateCb(data);
        // }, error => {
        //     console.error(error);
        // });
        // if(removeCb){
        //     ref.on('value', (childSnapshot, prevChildKey) => {
        //         var data = childSnapshot.val();
        //         console.log("child remove", data);
        //         //callback(data);
        //         removeCb(data);
        //     });
        // }
    };
    MonitoringService.prototype.canOrder = function (statusMap, offlineTrigger) {
        var canOrder = false;
        var currentTime = new Date().getTime();
        // tslint:disable-next-line: forin
        for (var key in statusMap) {
            var station = statusMap[key];
            var info = station['status'];
            var state = info['status'];
            var isByod = key.split('-')[1] === '17';
            if (state === 'online' && currentTime - info['datetime'] < offlineTrigger * 60000 && isByod) {
                canOrder = true;
                break;
            }
            // if (this.orderma.urlMode == 'prekiosk')
            // {
            //     if (state === 'online' && currentTime - info['datetime'] < offlineTrigger * 60000 && !isByod) {
            //         canOrder = true;
            //         break;
            //     }
            // }else
            // {
            //     if (state === 'online' && currentTime - info['datetime'] < offlineTrigger * 60000 && isByod) {
            //         canOrder = true;
            //         break;
            //     }
            // }
        }
        return canOrder;
    };
    MonitoringService.prototype.listenOrders = function (nodeName, listCb, updateCb, removeCb) {
        var ref = this.database.ref(nodeName);
        this.refsByKey[nodeName] = ref;
        // ref.on('value', (snapshot) => {
        //     let data = snapshot.val();
        //     console.log('listenOrders once update', data);
        //     listCb(data);
        // }, error => {
        //     console.error(error);
        //     listCb(null);
        // });
        ref.orderByChild('status').equalTo('received').on('value', function (childSnapshot, prevChildKey) {
            var data = childSnapshot.val();
            console.log('listenOrders child update', data);
            // callback(data);
            // updateCb(data);
            listCb(data);
        }, function (error) {
            console.error(error);
            listCb(null);
        });
        if (removeCb) {
            ref.orderByChild('status').equalTo('received').on('child_removed', function (childSnapshot, prevChildKey) {
                var data = childSnapshot.val();
                console.log('listenOrders child remove', data);
                // callback(data);
                removeCb(data);
            });
        }
    };
    MonitoringService.prototype.listenStoreKioskStatus = function (store, offlineHandler, onlineHandler) {
        console.log('listen kiosk status 2');
        /*

        this.listenStation("storeId", store.id, (result) => {
            this.monitorResultHandling(result, offlineHandler, onlineHandler);
        }, () => { });
        */
        this.listenBatchStoreStatus(store.id, function (status) {
            console.log('kiosk status', status);
            if (status && status.order) {
                onlineHandler();
            }
            else {
                offlineHandler();
            }
        }, null, null);
    };
    MonitoringService.prototype.listenStoreStarPrintStatus = function (storeId, handler) {
        this.listenStation('storeId', Number(storeId), function (result) {
            handler(result);
        }, function (result) {
            handler(result);
        });
    };
    MonitoringService.prototype.monitorResultHandling = function (result, offlineHandler, onlineHandler) {
        var canOrder = false;
        var currentTime = new Date().getTime();
        for (var key in result) {
            var status_1 = result[key]['status']['status'];
            console.log(currentTime - result[key]['status']['datetime']);
            if (status_1 == 'online' && currentTime - result[key]['status']['datetime'] < 15 * 60000) {
                canOrder = true;
                break;
            }
        }
        var allKioskOffline = !canOrder;
        this.unlistenAll();
        console.log(result);
        if (allKioskOffline) {
            offlineHandler();
        }
        else {
            onlineHandler();
        }
    };
    MonitoringService.prototype.listenStation = function (attName, attValue, listCb, updateCb) {
        this.listen('status', attName, attValue, listCb, updateCb);
    };
    MonitoringService.prototype.listenPrinterOrder = function (printAddress, orderNo, updateCb) {
        var node = 'printer/' + printAddress + '/orders/' + orderNo;
        this.listenValueChange(node + '/status', 'status' + orderNo, 'status', updateCb);
        this.listenValueChange(node + '/error', 'error' + orderNo, 'error', updateCb);
    };
    MonitoringService.prototype.listenValueChange = function (nodeName, id, attName, updateCb) {
        var _this = this;
        var ref = this.database.ref(nodeName);
        this.refsByKey[id] = ref;
        ref.on('value', function (snapshot) {
            var data = snapshot.val();
            console.log('update', data);
            // callback(data);
            var params = { id: id, type: attName, value: data };
            updateCb(params);
            console.log(_this.refsByKey);
        }, function (error) {
            console.error(error);
        });
    };
    MonitoringService.prototype.listen = function (nodeName, attName, attValue, listCb, updateCb, removeCb) {
        var ref = this.database.ref(nodeName);
        this.refsByKey[attValue] = ref;
        ref.orderByChild(attName).equalTo(attValue).once('value', function (snapshot) {
            var data = snapshot.val();
            console.log('once update', data);
            listCb(data);
        }, function (error) {
            console.error(error);
            listCb(null);
        });
        ref.orderByChild(attName).equalTo(attValue).on('child_changed', function (childSnapshot, prevChildKey) {
            var data = childSnapshot.val();
            console.log('child update', data);
            // callback(data);
            updateCb(data);
        }, function (error) {
            console.error(error);
        });
        if (removeCb) {
            ref.orderByChild(attName).equalTo(attValue).on('child_removed', function (childSnapshot, prevChildKey) {
                var data = childSnapshot.val();
                console.log('child remove', data);
                // callback(data);
                removeCb(data);
            });
        }
    };
    MonitoringService.prototype.listenStationByKey = function (keyValue, listCb, updateCb) {
        var ref = this.database.ref('status/' + keyValue);
        this.refsByKey.push(ref);
        ref.once('value', function (snapshot) {
            var data = snapshot.val();
            console.log('detail once update', data);
            listCb(data);
        });
        ref.on('value', function (snapshot) {
            var data = snapshot.val();
            console.log('detail on update', data);
            // callback(data);
            updateCb(data);
        });
    };
    MonitoringService.prototype.unlistenAll = function () {
        console.log('unlisten all');
        for (var id in this.refsByKey) {
            if (this.refsByKey[id]) {
                this.refsByKey[id].off();
            }
        }
        this.refsByKey = {};
    };
    MonitoringService.prototype.unlistenByKey = function (id) {
        if (this.refsByKey[id]) {
            this.refsByKey[id].off();
            console.log('detail listen is stop', id);
            delete this.refsByKey[id];
        }
    };
    MonitoringService.prototype.unlistenOrderPair = function (orderNo, callback) {
        var statusId = 'status' + orderNo;
        var errorId = 'error' + orderNo;
        this.unlistenByKey(statusId);
        this.unlistenByKey(errorId);
        if (callback) {
            callback();
        }
        console.log(this.refsByKey);
    };
    MonitoringService.prototype.isOrderMonitoring = function (orderNo) {
        var statusId = 'status' + orderNo;
        var errorId = 'error' + orderNo;
        return this.isMonitoring(statusId) && this.isMonitoring(errorId);
    };
    MonitoringService.prototype.isMonitoring = function (id) {
        var tmp = this.refsByKey[id];
        if (tmp) {
            return true;
        }
        else {
            return false;
        }
    };
    MonitoringService.prototype.listenFireStoreOrder = function () {
    };
    MonitoringService.ngInjectableDef = i0.defineInjectable({ factory: function MonitoringService_Factory() { return new MonitoringService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService)); }, token: MonitoringService, providedIn: "root" });
    return MonitoringService;
}(BaseService));
export { MonitoringService };
