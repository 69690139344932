var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { Injector } from '@angular/core';
import { NavParams } from '@ionic/angular';
/**
 * Generated class for the Passcode page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */
var PasscodeDialog = /** @class */ (function (_super) {
    __extends(PasscodeDialog, _super);
    function PasscodeDialog(injector, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.wrong = false;
        _this.password = _this.navParams.get('passcode');
        console.log('construct PasscodeDlg');
        return _this;
    }
    PasscodeDialog.prototype.dismissClicked = function () {
        this.modalController.dismiss(null);
        console.log('dismiss?');
    };
    PasscodeDialog.prototype.submit = function () {
        if (this.passcode === this.password) {
            this.modalController.dismiss(this.passcode);
        }
        else {
            this.wrong = true;
            this.passcode = '';
            this.myInput.setFocus();
        }
    };
    return PasscodeDialog;
}(MobileBasePage));
export { PasscodeDialog };
