import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Brand, Link, Offer, OpeningHours, Period, Reward, Slot, Store} from 'aigens-ng-core';
import * as assert from 'assert';

class CountryCode {
    country: string;
    code: string;
    length?: string | number;
    regex?: RegExp;
}

/** to form logic  start */
export const defaultCountryCodes: CountryCode[] = [
    {country: 'US/CA', code: '1', length: 10, regex: /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/},
    {country: 'NL', code: '31'},
    {country: 'BE', code: '32'},
    {country: 'FR', code: '33', length: '7-14'},
    {country: 'ES', code: '34'},
    {country: 'UK', code: '44'},
    {country: 'DE', code: '49'},
    {country: 'MY', code: '60', length: 10, regex: /^0?(1[1,5]?\d{8}|[4-7,9]\d{7}|8[2-9]\d{6}|3\d{8})$/},
    {country: 'AU', code: '61', length: '9-10'},
    {country: 'ID', code: '62', length: '8-12', regex: /^(0|8)\d{2}\d{6,9}/},
    {country: 'PH', code: '63', regex: /^09[0-9]{9}$|^0?2[0-9]{7}$|^0?32[0-9]{7}$/},
    {country: 'SG', code: '65', length: 8, regex: /^[689]{1}[0-9]{7}$/},
    {country: 'TH', code: '66', length: 9},
    {country: 'VN', code: '84', regex: /^0?(2|[35789])[0-9]{8}$|^02[48][0-9]{8}$/},
    {country: 'HK', code: '852', length: 8, regex: /^((?!999)([2-9][0-9]{7}))$/},
    {country: 'MO', code: '853', length: 8},
    {country: 'CN', code: '86', length: 11},
    {country: 'TW', code: '886', length: 10, regex: /^0([1-8]{1}[0-9]{7,8}|9[0-9]{8})$/},
    {country: 'ID', code: '91', regex: /^([6-9][0-9]{9}|22[0-9]{8})$/}
];

// 8-12 letters and numbers
export function passwordFormatValidator(control: AbstractControl): ValidationErrors | null {
    return isPasswordVaild(control.value) ? null : {'invalid password format': 'password invaild'};
}

export function confirmPasswordFormatValidator(control: AbstractControl): ValidationErrors | null {
    const input = control.value;
    const isValid = control.root.value['Password'] === input;
    if (!isValid) {
        return {'equalTo': {isValid}};
    } else {
        return null;
    }
}

export function isPasswordVaild(value: string): Boolean {
    let isVaild = false;
    if (value) {
        isVaild = ((/[a-z]/.test(value) && /[0-9]/.test(value)) || (/[0-9]/.test(value) && /[A-Z]/.test(value)));
    }
    return isVaild;
}

// by '852' number-string return 'hk'
export function findCountryByCodes(value: string) {
    let key;
    for (let k in defaultCountryCodes) {
        if (defaultCountryCodes[k].code === value) {
            key = defaultCountryCodes[k].country;
            break;
        }
    }
    return key || 'HK';
}

export function phoneValidator(control): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
    }
    return isPhoneNumberValid(control.value.toString()) ? null : {'mobile phone invalid': 'phone number invalid'};
}

export function isEmptyInputValue(value: string): boolean {
    return value == null || (typeof value === 'string' && value.length === 0) || value === undefined;
}

export function isPhoneNumberValid(value): Boolean {
    let isValid = false;
    if (value !== undefined) {
        // 不能以 0/1 開頭
        // isValid = value.length == 8 && /^([2-9])\d{7}/.test(value);

        const newValue = value.trim();

        if (newValue) {
            isValid = newValue.length > 7 && /^\d+$/.test(newValue);
        } else {
            isValid = value.length > 7 && /^\d+$/.test(value);
        }

    }
    return isValid;

}

export function isPhoneValid(phone: string, country = 'HK'): boolean {
    let isValid = false;
    const entry = defaultCountryCodes.find(i => i.country.indexOf(country) !== -1 || i.code.indexOf(country) !== -1);
    if (entry && entry.regex) { // 若harcode有国家的phone正则， 则使用它
        return entry.regex.test(phone);
    }

    if (!onlyHasNumber(phone)) {
        return false;
    }

    if (phone !== undefined && phone !== '') {
        const length = entry.length;
        const [min, max] = {...String(length).split('-')};
        if (max) {
            if (phone.length >= Number(min) && phone.length <= Number(max)) {
                isValid = true;
            }
        } else {
            if (phone.length === length)
                isValid = true;
            else {

                // 当号码有前缀也通过 比如说 85244090771 而不只是 44090771
                let countryCode = phone.slice(0, phone.length - Number(length));

                isValid = phone.length === (entry.code.length + Number(length)) && countryCode === getCountryCallingCode(country);
            }
        }
    }

    return isValid;
}

function matchLength(input: number, match: string | number) {
    if (typeof match === 'number') {
        return input === match;
    } else {
        assert(typeof match === 'string');
        const [min, max] = {...match.split('-')}.map(i => Number(i));
        return input >= min && input <= max;
    }
}

// 前提是通过电话验证 isPhoneValid()
export function isPhoneIncludingCode(phone, country = 'HK') {
    let isSeparate = false; // 是否分隔
    let isIncludingCode = false; // 是否has country code
    let separateSymbol = null; // 分隔符号
    let noCodePhone = '';

    if (isPhoneValid(phone, country)) {
        if (matchLength(phone.length, getPhoneLength(country))) {
            return {isSeparate, isIncludingCode, separateSymbol, noCodePhone: phone};
        } else {
            // including code
            if (matchLength(phone.length - getCountryCallingCode(country).length, getPhoneLength(country))) {
                // not has separateSymbol
                isIncludingCode = true;
                noCodePhone = phone.slice(getCountryCallingCode(country).length);
                return {isSeparate, isIncludingCode, separateSymbol, noCodePhone};
            } else {
                // has separateSymbol
                isIncludingCode = true;
                isSeparate = true;
                separateSymbol = phone.slice(getCountryCallingCode(country).length, getCountryCallingCode(country).length + 1);
                noCodePhone = phone.slice(getCountryCallingCode(country).length + 1);
                return {isSeparate, isIncludingCode, separateSymbol, noCodePhone};
            }

        }
    } else {
        return {};
    }
}
function test(value){
    value = value.toLowerCase();
        let isValid = false;
        if (value !== undefined) {
            const regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            //    isVaild = value.includes("@") && (value.includes(".com") || value.includes(".net"));
            isValid = regExp.test(value) && (!value.includes('.web')) && (!value.includes('..')) && value.indexOf('.') !== 0;
            isValid = isValid && !(value.includes('<') && value.indexOf('>') === value.length - 1) &&
                value.indexOf('@') === value.lastIndexOf('@') && value.indexOf(')') !== value.length - 1;
            isValid = isValid && !(/^\d+$/.test(value[value.length - 1]) && (value.length - 1 - value.lastIndexOf('.') >= 4));
        }
        return isValid;
}
export function isEmailValid(value: string): boolean {
    try {
        value = value.toLowerCase();
        let isValid = false;
        if (value !== undefined) {
            const regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            //    isVaild = value.includes("@") && (value.includes(".com") || value.includes(".net"));
            isValid = regExp.test(value) && (!value.includes('.web')) && (!value.includes('..')) && value.indexOf('.') !== 0;
            isValid = isValid && !(value.includes('<') && value.indexOf('>') === value.length - 1) &&
                value.indexOf('@') === value.lastIndexOf('@') && value.indexOf(')') !== value.length - 1;
            isValid = isValid && !(/^\d+$/.test(value[value.length - 1]) && (value.length - 1 - value.lastIndexOf('.') >= 4));
        }
        return isValid;
    } catch (err) {
        return false;
    }

}

export function isNameValid(value: string): boolean {
    return validateName(value);

}

export function emailFormatValidator(control): ValidationErrors | null {
    return isEmailValid(control.value) ? null : {'email format': 'email invaild'};
}

// let defulatLangs = ['en', 'zh', 'zh-cn'];
export function firstNameFormatValidator(control: AbstractControl, errorText?): ValidationErrors | null {
    return validateName(control.value) ? null : {'first name format': errorText || 'Please enter name in First Name, First Name format'};
}

export function lastNameFormatValidator(control: AbstractControl, errorText?): ValidationErrors | null {
    return validateName(control.value) ? null : {'last name format': errorText || 'Please enter name in Last Name, Last Name formate'};
}

export function isNameVaild(username: string): boolean {
    let isVaild = false;
    if (username) {
        isVaild = /^[A-Za-z\s]+$/.test(username);
    } else {
        isVaild = true;
    }
    return isVaild;
}

export function validateName(username: string): boolean {
    let name = new RegExp('^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z_]| ){1,20}$'); // 不包含“-”
    if (username != null || typeof username === 'undefined' || !username) {
        return name.test(username);
    } else {
        return false;
    }
}


export function onlyHasNumber(string: string): Boolean {
    const regExp = /^\d+$/;
    return regExp.test(string);
}

// by country return '852': number-string
export function getCountryCallingCode(country = 'HK'): string {
    let tmp = defaultCountryCodes.find(i => i.country.indexOf(country) !== -1);
    if (!tmp) {
        return '852';
    }
    return tmp.code;
}

export function getPhoneLength(country): string | number {
    return defaultCountryCodes.find(i => i.country.indexOf(country) !== -1).length;
}

export function isUnitNumberFormat(string: string): boolean {
    const regExp = /[^A-Za-z0-9 /-]/;
    let onlyNumberAndLetterAndSpace: boolean;
    onlyNumberAndLetterAndSpace = !regExp.test(string);
    return onlyNumberAndLetterAndSpace;
}

export function asLetter(string: string): Boolean {
    const regExp = /[A-Za-z]/;
    return regExp.test(string);
}

export function hasNumber(string: string): Boolean {
    const regExp = /[0-9]/;

    return regExp.test(string);
}

export function getDateStringLabel(milliseconds: number): string {
    const date = new Date(milliseconds);
    let timeString = date.getFullYear().toString() + '-';
    if ((date.getMonth() + 1) <= 9) {
        timeString = timeString + '0' + (date.getMonth() + 1).toString() + '-';
    } else {
        timeString = timeString + (date.getMonth() + 1).toString() + '-';
    }

    if (date.getDate() <= 9) {
        timeString = timeString + '0' + date.getDate().toString();
    } else {
        timeString = timeString + date.getDate().toString();
    }

    return timeString;
}


export function currentTimeString(): string {
    return timeStringLabel(null, true);
}

export function timeStringLabel(pickUpTime?: number, withSecond: boolean = false): string {
    let date: Date;
    if (pickUpTime) {
        date = new Date(pickUpTime);
    } else {
        date = new Date();
    }
    let timeString = date.getHours() + ':';
    if (date.getMinutes() === 0) {
        timeString = timeString + '00';
    } else if (date.getMinutes() <= 9) {
        timeString = timeString + '0' + date.getMinutes();
    } else {
        timeString = timeString + date.getMinutes();
    }

    if (withSecond) {
        timeString = timeString + ':';
        if (date.getSeconds() === 0) {
            timeString = timeString + '00';
        } else if (date.getSeconds() <= 9) {
            timeString = timeString + '0' + date.getSeconds();
        } else {
            timeString = timeString + date.getSeconds();
        }
    }
    return timeString;
}

export function getClosedTime(store: Store): string {
    let storeCloseTime: string;
    const today: Date = new Date();
    if (store.openings && store.openings['weekdays']) {
        const weekday = today.getDay();
        storeCloseTime = store.openings['weekdays'][weekday]['endTime'];

    }

    if (!storeCloseTime) {
        return '24:00';
    }

    return storeCloseTime;


}

export function getPickupDelayTime(store: Store): number {
    if (!store) return 0;
    if (!store.pos) {
        return 0;
    }
    return Number(store.pos['pickupDelay']);
}

export function getDeliveryDelayTime(store: Store): number {
    if (!store) return 0;
    if (!store.pos) {
        return 0;
    }
    return Number(store.pos['deliveryDelay']);
}

export function getTodayMillisecondsFromTimeString(timeLabel: string): number {
    const date = new Date();
    if (!isNaN(Number(timeLabel.split(':')[0]))) {
        if (timeLabel.split(':').length === 2) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), 0, 0);

        } else if (timeLabel.split(':').length === 3) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), Number(timeLabel.split(':')[2]), 0);

        } else if (timeLabel.split(':').length === 4) {
            date.setHours(Number(timeLabel.split(':')[0]), Number(timeLabel.split(':')[1]), Number(timeLabel.split(':')[2]), Number(timeLabel.split(':')[3]));
        }
    } else {
        return 0;
    }

    return Number(timeLabel.split(':')[0]) === 0 ? (date.getTime() + (24 * 60 * 60000)) : date.getTime();
}

export function isAvailableOffer(offer: Offer): boolean {
    const current = new Date().getTime();
    const start = offer.start ? offer.start : current;
    const end = offer.expire ? offer.expire : current;

    return start <= current && end >= current;

}

export function isAvailableReward(reward: Reward): boolean {
    const current = new Date().getTime();
    const start = reward.start ? reward.start : current;
    const end = reward.end ? reward.end : current;

    return start <= current && end >= current;

}

export function isAvailablePeriod(store, period: Period, now: Date) {
    // var today: Date = new Date();
    // today.setHours(0, 0, 0, 0);
    // var diff = now.getTime() - today.getTime();
    // var start:number;
    // var end:number;

    // //console.log("diff", diff);
    // if(period.openings && period.openings.weekdays){
    //     var [dateStart,startString,dateEnd,endString] = this.getWeekdayStartAndEnd(period);
    //     start = dateStart.getTime() - today.getTime();
    //     end = dateEnd.getTime() - today.getTime();
    // }else{
    //     if (!period.start || !period.end) return false;

    //     start = period.start;
    //     end = period.end;

    // }


    // return diff >= start && diff < end;
    if (period.openings) {

        console.log('isAvailable : check by opening rules');

        const open = OpeningHours.isTimeOpen(store.brand.openings, period.openings, now.getTime());

        return open;

    } else {

        // console.log("diff", diff);

        if ((typeof period.start) === 'undefined' || (typeof period.end) === 'undefined') {
            return false;
        }

        console.log('isAvailable : check by start & end');

        return Period.isTimeOpen(period, now.getTime());
    }
}

export function getWeekdayStartAndEnd(period: Period): [Date, string, Date, string] {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    const weekday = period['savedWeekday'] !== null ? period['savedWeekday'] : today.getDay();
    const weekdays = period.openings.weekdays;
    let start: Date = new Date();
    let end: Date = new Date();
    let startTime: string;
    let endTime: string;
    if (weekdays[weekday]['startTime'] && weekdays[weekday]['endTime']) {
        startTime = weekdays[weekday]['startTime'];
        endTime = weekdays[weekday]['endTime'];

    } else {
        startTime = period.startTime;
        endTime = period.endTime;

    }
    start.setHours(Number(startTime.split(':')[0]), Number(startTime.split(':')[1]), 0, 0);
    end.setHours(Number(endTime.split(':')[0]), Number(endTime.split(':')[1]), 0, 0);
    if (today.getDay() !== weekday) {
        if (end < start) {
            start = new Date(start.getTime() - 24 * 60 * 60000);

        } else {
            start = new Date(start.getTime() - 24 * 60 * 60000);
            end = new Date(end.getTime() - 24 * 60 * 60000);

        }
    } else {
        if (end < start) {
            console.log('stepped over time to next day!!!');
            end = new Date(end.getTime() + 24 * 60 * 60000);
        }

    }

    return [start, startTime, end, endTime];

}

export function hasHoliday(brand: Brand, oh: OpeningHours, time = new Date().getTime()) {

    if (!oh) {
        return null;
    }


    if (oh.overwrites) {
        for (const slot of oh.overwrites) {
            if (Slot.isWithinDayRange(slot, time)) {
                console.log('within overwrites', slot);
                return slot;
            }
        }
    }

    if (brand.openings) {

        if (brand.openings.ph1) {
            for (const slot of brand.openings.ph1) {
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log('within holiday1', slot);
                    return oh.holidays[0];
                }
            }


        }

        if (brand.openings.ph2) {
            for (const slot of brand.openings.ph2) {
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log('within holiday2', slot);
                    return oh.holidays[1];
                }
            }
        }

    }

    return null;
}

export function isPeriodClosed(store: Store, period: Period, currentTime: number): boolean {
    if (!period) {
        return false;

    } else {
        const preStop = period['prestop'] ? period['prestop'] : 0;

        if (preStop === 1440) {
            return true;
        }
        let endTime = new Date();
        const holidaySlot = hasHoliday(store.brand, period.openings);

        if (holidaySlot) {
            const startTime = new Date();
            // endTime.setHours(Number(holidaySlot.endTime.split(":")[0]), Number(holidaySlot.endTime.split(":")[1]));
            startTime.setHours(Number(holidaySlot.startTime.split(':')[0]), Number(holidaySlot.startTime.split(':')[1]), 0, 0);
            endTime.setHours(Number(holidaySlot.endTime.split(':')[0]), Number(holidaySlot.endTime.split(':')[1]), 0, 0);
            if (endTime < startTime) {
                console.log('stepped over time to next day!!!');
                if (new Date() > endTime) {
                    endTime = new Date(endTime.getTime() + 24 * 60 * 60000);
                }
            }

        } else if (period.openings && period.openings.weekdays) {
            const [dateStart, startString, dateEnd, endString] = getWeekdayStartAndEnd(period);
            endTime = dateEnd;
        } else {
            const startTime = new Date();

            startTime.setHours(Number(period.startTime.split(':')[0]), Number(period.startTime.split(':')[1]), 0, 0);
            endTime.setHours(Number(period.endTime.split(':')[0]), Number(period.endTime.split(':')[1]), 0, 0);
            if (endTime < startTime) {
                console.log('stepped over time to next day!!!');
                if (new Date() > endTime) {
                    endTime = new Date(endTime.getTime() + 24 * 60 * 60000);
                }
            }

        }
        console.log('endTime:' + (endTime.getTime() - preStop * 60000));
        console.log('currentTime:' + currentTime);
        return (endTime.getTime() - preStop * 60000) < currentTime;

    }

}

export function getTandCUrl(brand: Brand, name: string): string {
    if (!brand.links) {
        return null;
    }
    const link = brand.links[name] as Link;
    if (!link) {
        return null;
    }

    let url = link.url;

    if (!url) {
        return null;
    }

    if (url.indexOf('http://') === 0 && url.indexOf('localhost') === -1) {
        url = url.replace('http:', 'https:');
    }

    return url;
}

/** to form logic  end */
